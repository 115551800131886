import React from "react";
import AboutUspage from "../Hub-Components/AboutUs-Component/AboutUs";
import Goals from "../Hub-Components/AboutUs-Component/Goals";
import RVSYuvaIcons from "../Hub-Components/AboutUs-Component/RVSYuvaIcons";
import TrusteeSirQuote from "../Hub-Components/AboutUs-Component/TrusteeSirquotes";
// import FooterSection from "../Hub-Components/Footer";
// import HeaderSlider from "../Hub-Components/Home-Component/HeaderSlider";
// import Navbar from "../Hub-Components/Navbar";
import SubHeader from "../Hub-Components/SubHeader";
// import AboutUsImage from "../Asset/Images/about-us.jpg"
// import Vision from "../Hub-Components/AboutUs-Component/Vision";
// import CommingSoon from "../Hub-Components/CommingSoon";
import Mentor from "../Hub-Components/AboutUs-Component/Mentor";
import Scope from "../Hub-Components/AboutUs-Component/Scope";

function AboutUs(){
    return(
        <div>
          <SubHeader breadcrumbs="About us" first_breadcrumbs_link=""/>
          <AboutUspage/>
          <TrusteeSirQuote/>
          <Goals/>
           <RVSYuvaIcons/>
           <Scope/>
           <Mentor/>
          {/* <SubHeader breadcrumbs="About us" first_breadcrumbs_link="activity-club"/>
         
        
          {/* <Goals/>
          
          <Vision/>
          <FooterSection/>  */}
        </div>
    )
}
export default AboutUs