import React from "react";
import RVSYUVA from "../../Asset/Images/Home/about_yuva.png"
// import Design from "../Asset/Images/Home/design.png"
function AboutYuvaPage() {
    return (
        <div className="pb-16 pt-8">
            <div className=" bg-primary my-16 ">
                <div className="container mx-auto ">
                    <div className="flex w-[100%] sm:block">
                        <div className="w-[30%] sm:hidden mdsm:hidden lg:block  relative sm:w-[100%] ">
                            <img src={RVSYUVA} alt="RVS Yuva" className="absolute sm:relative top-8 z-10 right-[-20px]" />
                        </div>
                        <div className="w-[70%] mdsm:w-[80%] sm:w-[100%] aboutyuva bg-white py-10 sm:py-0">
                            <h2 className="text-primary sm:text-h2 sm:text-center sub-heading text-h1 pb-3">RVS <span className="text-secondary sm:text-h2 sm:text-center sub-heading text-h1 pb-3">YUVA</span> </h2>
                            <p className="leading-[26px] sm:text-sm sm:px-6 text-text-color tracking-wide">
                                RVS YUVA SULUR is a social effort with the goal of empowering talent without regard for borders. It guides the youth towards employment in academic and non-academic fields. It focuses on career advancement and transitioning first-generation learners from blue collar to white collar jobs. It aims to provide a forum for  aspiring <strong> children aged 13 to 21</strong> to express their skills without regard for boundaries. RVS YUVA made an important milestone towards beginning a new career beyond the curriculum in an effort to give everyone an equal opportunity to advance without restrictions and in recognition of the significance of extracurricular activities and the beneficial outcomes they have on students' lives as well as employability.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AboutYuvaPage