import React from "react";
// import thankpop from "../Asset/Images/thankpop.webp"
// import Navbar from "../Hub-Components/Navbar";
function Thankyou(){
    return(
        <div className="relative">
        <div className="bg-primary h-[80vh] thankpopup">
            <div className="absolute ">

            </div>
            {/* <img src={thankpop}/> */}
    
            {/* <FooterSection/> */}
        </div>
        </div>
    )
}
export default Thankyou