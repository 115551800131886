import React from "react";
function TrusteeSirQuote() {
    return (
        <div className="bg-background py-16">
            <div className="container mx-auto py-4">
                <div className="w-[70%] sm:w-[100%] mdsm:mx-4 lg:mx-0 sm:mx-0 border-l-8 border-primary" >
                    <p className="architects-daughter-font text-[18px] sm:text-[16px] text-text-color px-8">We have always believed in empowering youth through education with a special focus on transforming the lives of first-generation learners for over three and half decades</p>
                </div>
                <div className="w-[100%]">
                    <p className="architects-daughter-font text-[16px] sm:text-sm pt-4 text-right text-secondary px-8 font-semibold"> Managing Trustee, RVS Educational Trust,</p>
                    <p className="architects-daughter-font text-[16px] sm:text-sm pt-4 text-right text-secondary px-8 font-semibold"> -- Dr. Senthil Ganesh Kuppuswamy</p>

                </div>
            </div>
        </div>
    )
}
export default TrusteeSirQuote