import React from "react"
import Artistry from "../Hub-Components/Activity-Component/Artistry"
import Capture from "../Hub-Components/Activity-Component/Capture"
import Melodies from "../Hub-Components/Activity-Component/Melodies"
import Rhythm from "../Hub-Components/Activity-Component/Rhythm"
import ShortFlim from "../Hub-Components/Activity-Component/ShortFlim"
import Sports from "../Hub-Components/Activity-Component/Sports"
// import Navbar from "../Hub-Components/Navbar"
import SubHeader from "../Hub-Components/SubHeader"
// import ActivityClubBanner from "../Asset/Images/banners.jpg"
// import CommingSoon from "../Hub-Components/CommingSoon"
// import FooterSection from "../Hub-Components/Footer"
function ActivityClub() {
    return (
        <div>
            <SubHeader breadcrumbs="Activity Club" first_breadcrumbs_link="activity-club" />
            <Melodies />
            <Capture />
            <Artistry />
            <Rhythm />
            <Sports />
            <ShortFlim />
        </div>
    )
}
export default ActivityClub