import React from 'react'
// import { RiFacebookLine, RiInstagramLine } from "react-icons/ri";

export default function ShortFlim() {
    return (
        <div className='pt-16 pb-16'>
            <div className='container mx-auto short-films-background-image px-4'>
                <div className='center'>
                    <h2 className="text-primary sm:text-h2 sm:text-center sub-heading text-h1 pb-3">YUVA <span className="text-secondary sm:text-h2 sm:text-center sub-heading text-h1 pb-3">SHORT FILM</span> </h2>
                </div>
                <p className='text-text-color leading-[26px] tracking-wide pb-3 sm:text-sm'>
                    Now is the time for film buffs everywhere to embrace short films. Short films are substantially less costly than feature films and are not planned to generate a lot of revenue. It means that directors are far more likely to finance movies that aim to be braver and more experimental. It encourages filmmakers to experiment much more and create a specific style. So we will give opportunities to the students who love to write, do makeup, choreography, voice-over artist, Editor, Cameraman, etc. We are ready to train you.
                </p>
                <p className='text-text-color leading-[26px] tracking-wide pb-3 sm:text-sm'>
                    YUVA Short film club started classes for acting, Directing, and Script Writing which involve recreating ads and conveying the same dialogues with various emotions. Hurry up to be a professional. We have lots of plans. We will discuss it with you in the YUVA Short film club.
                </p>
                {/* <div className='w-[100%]'>
          <div className='flex justify-end'>
            <a href="https://www.facebook.com/RVSYuvaSulur.Melodies?mibextid=ZbWKwL">
            <div className='group cursor-pointer transition07 mx-3'>
              <div className='bg-[#d2d2d3] p-[2px] group-hover:bg-secondary'>
                <RiFacebookLine className='text-secondary text-h2 transition07 group-hover:text-white ' />
              </div>
            </div>
            </a>
            <a href="https://www.instagram.com/rvsyuvasulur.melodies/">
            <div className='group cursor-pointer transition07'>
              <div className='bg-[#d2d2d3] p-[2px] group-hover:bg-secondary'>
                <RiInstagramLine className='text-secondary text-h2 transition07 group-hover:text-white ' />
              </div>
            </div>
            </a>
          </div>
        </div> */}
            </div>
        </div>
    )
}
