import React from 'react'
import { RiFacebookLine, RiInstagramLine } from "react-icons/ri";

export default function Artistry() {
  return (
    <div className='pt-16'>
      <div className='container mx-auto artist-background-image px-4'>
        <div className='center'>
          <h2 className="text-primary sm:text-h2 sm:text-center sub-heading text-h1 pb-3">YUVA <span className="text-secondary sm:text-h2 sm:text-center sub-heading text-h1 pb-3">ARTISTRY</span> </h2>
        </div>
        <p className='text-text-color leading-[26px] tracking-wide pb-3 sm:text-sm'>
          Art is not just a word, it's the mirror of our thoughts. We established the YUVA Artistry club to give art a soul. It offers instruction in drawing, painting, and other artistic endeavors. You can view student artwork on the YUVA Artistry Instagram page.
        </p>
        <p className='text-text-color leading-[26px] tracking-wide pb-3 sm:text-sm'>
          Different varieties of Artwork and sketching of individuals, along with other forms of artistry, are welcomed here. The students who relish sketching and are innovative in coming up with unique concepts would put up their works on the platform RVS YUVA ARTISTRY. Pencil Sketching, Craftwork, and all other artworks are posted. It not only recognizes talents but also can help them to take these as a career. Further updates on this group will be posted, so stay tuned.
        </p>
        <div className='w-[100%]'>
          <div className='flex justify-end'>
            <a href="https://www.facebook.com/RVSYuvaSulur.Artistry?mibextid=ZbWKw">
            <div className='group cursor-pointer transition07 mx-3'>
              <div className='bg-[#d2d2d3] p-[2px] group-hover:bg-secondary'>
                <RiFacebookLine className='text-secondary text-h2 transition07 group-hover:text-white ' />
              </div>
            </div>
            </a>
            <a href="https://www.instagram.com/rvsyuvasulur.artistry">
            <div className='group cursor-pointer transition07'>
              <div className='bg-[#d2d2d3] p-[2px] group-hover:bg-secondary'>
                <RiInstagramLine className='text-secondary text-h2 transition07 group-hover:text-white ' />
              </div>
            </div>
            </a>
          </div>
        </div>
      </div>
      
    </div>
  )
}
