import React from 'react'
import { RiFacebookLine, RiInstagramLine } from "react-icons/ri";

export default function Sports() {
  return (
    <div className='pt-16'>
      <div className='container mx-auto sports-background-image px-4'>
        <div className='center'>
          <h2 className="text-primary sm:text-h2 sm:text-center sub-heading text-h1 pb-3">YUVA <span className="text-secondary sm:text-h2 sm:text-center sub-heading text-h1 pb-3">SPORTS</span> </h2>
        </div>
        <p className='text-text-color leading-[26px] tracking-wide pb-3 sm:text-sm'>
          A fundamental aspect of human life is sports. It's a fantastic practice that strengthens athleticism, nurtures discipline, and entirely positively transforms the personality. Maintaining good mental and physical health is essential for success in life.
        </p>
        <p className='text-text-color leading-[26px] tracking-wide pb-3 sm:text-sm'>
          According to sources, those involved in sports from an early age develop potent personalities. Any person's ability to succeed depends on their physical and mental stamina. Therefore, engaging in sports activities is the best approach to maintaining an individual's psychological and physical strength.
        </p>
        <p className='text-text-color leading-[26px] tracking-wide pb-3 sm:text-sm'>
          RVS YUVA SPORTS provides required resources and training for the students to equip themselves for participating in other league and international competitions. Students can also be employed when they reach a particular level of games with prerequisite scores and ratings to enter the government sector. Thus their passion for sports and employability will be given importance. For further updates, keep in touch with us.
        </p>
        <div className='w-[100%]'>
          <div className='flex justify-end'>
            <a href="https://www.facebook.com/rvsyuvasulur.sports?mibextid=ZbWKwL">
            <div className='group cursor-pointer transition07 mx-3'>
              <div className='bg-[#d2d2d3] p-[2px] group-hover:bg-secondary'>
                <RiFacebookLine className='text-secondary text-h2 transition07 group-hover:text-white ' />
              </div>
            </div>
            </a>
            <a href="https://www.instagram.com/rvsyuvasulur.sports/">
            <div className='group cursor-pointer transition07'>
              <div className='bg-[#d2d2d3] p-[2px] group-hover:bg-secondary'>
                <RiInstagramLine className='text-secondary text-h2 transition07 group-hover:text-white ' />
              </div>
            </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
