
import { useState, useEffect } from 'react';
import axios from 'axios';

export default function ContactMap() {
    const [values, setValues] = useState({
        name: "",
        email: "",
        phone: "",
    });

    const [dataIsCorrect] = useState(false);
    const [send, setSend] = useState(false)
    const [errorsend, setErrorsend] = useState(false)
    const [errors, setErrors] = useState({});
    const [ setName] = useState('');
    const [ setEmail] = useState('');
    const [ setPhone] = useState('');
    const [isSaveClick, setIsSaveClick] = useState();

    useEffect(() => {
        if (Object.keys(errors).length === 0 && dataIsCorrect) {
            ContactMap(true);
        }
    }, [errors]);

    const validation = () => {
        let errors = {};
        let regex = /^[A-Za-z\s]+$/;
        let number = /^[0-9]/;
        let email = /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/
        if (values.name === "") {
            errors.name = "Name is Required";
        } else if (!regex.test(values.name)) {
            errors.name = "Name must be alphabets only";
        }
        if (values.email === "") {
            errors.email = "Email is Required";
        } else if (!email.test(values.email)) {
            errors.email = "Email is invalid";
        }

        if (values.phone === "") {
            errors.phone = "Mobile Number is Required";
        } else if (!number.test(values.phone)) {
            errors.phone = "Mobile Number should be numeric only";
        }

        return errors;
    };


    const handlesubmit = (event) => {
        event.preventDefault();
        setIsSaveClick(true);
        var errorVal = validation(values);
        setErrors(errorVal);

        if (Object.keys(errorVal).length === 0) {
            let myjson = {
                name: values.name,
                email: values.email,
                phone: values.phone,
            }
            // axios.post(`http://localhost:api/yuvacontact`, myjson)
            axios.post(`https://rvscollege.com/api/yuvacontact`, myjson)
                .then(res => {
                    setValues({
                        name: "",
                        email: "",
                        phone: ""
                    })
                    console.log("sddsw", res);
                    setSend(true);
                    setTimeout(() => {
                        setSend(false);
                    }, 3000);
                    setIsSaveClick(false);
                })

                .catch(err => {
                    console.log("ghk", err);
                    setValues({
                        name: "",
                        email: "",
                        phone: ""
                    })
                    setErrorsend(true);
                    setTimeout(() => {
                        setErrorsend(false);
                    }, 3000);
                    setIsSaveClick(false);
                })
        }

    }
    return (
        <div>
            <div className='container mx-auto py-16'>
                <div className='center'>
                    <h2 className="text-primary sm:text-h2 sm:text-center sub-heading text-h1 pb-3">CONTACT  <span className="text-secondary sm:text-h2 sm:text-center sub-heading text-h1 pb-3">US</span> </h2>
                </div>
                <div className='w-[100%] flex sm:block mdsm:block lg:flex'>
                    <div className="w-[60%] sm:w-[100%]  px-4 py-4">
                        <div className='pb-16'>
                            <p className='text-secondary sub-heading text-h2 sm:text-h4 pb-4 tracking-wider'>Contact Details</p>
                            <p className='text-text-color sm:text-sm pb-2'>Phone: 0422 2687 421/480/603</p>
                            <p className='text-text-color sm:text-sm pb-2'>Email: info@rvsgroup.com</p>

                            <p className='text-secondary sub-heading text-h2 sm:text-h4 pt-8 pb-4 tracking-wider'>Location</p>
                            <p className='text-text-color sm:text-sm pb-2'>242, Trichy Road,Sulur,</p>
                            <p className='text-text-color sm:text-sm pb-2'>Coimbatore – 641 402.Tamilnadu,</p>
                            <p className='text-text-color sm:text-sm pb-2'>South India.</p>
                        </div>
                        <iframe
                            title="RVS CAS College"
                            className="h-[350px] w-[100%] border-primary"
                            width="60%"
                            frameborder="0"
                            scrolling="no"
                            marginheight="0"
                            marginwidth="0"
                            src="https://maps.google.com/maps?width=700&amp;height=300&amp;hl=en&amp;q=RVS Institute of Management&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                        ></iframe>
                    </div>


                    <div className='w-[40%] sm:w-[100%] mdsm:w-[100%] mdsm:p-4 mdsm:py-8 lg:w-[40%] lg:p-4 sm:p-8'>

                        <div className=''>
                            <form className='bg-primary p-6 pb-6'>
                                <div>
                                    <label className={` font-base text-white sm:text-sm text-base`}> Name </label>
                                    <input type="text"
                                        value={values.name}
                                        id='name'
                                        placeholder={`${errors.name ? "*Name is Required!!" : ""}`}
                                        className={`${errors.name ? "placeholder:text-[#bb4848]" : ""} borderWhiteColor px-4 focus:outline-none focus:border-[#555352] w-[100%] h-[40px] sm:h-[30px] md:h-[30px] mdsm:h-[30px] lg:h-[40px] sm:text-sm placeholder:text-text-colo ${errors.name ? "bor1" : "border-black"} bor px-3 my-1`}
                                        onChange={(e) => {
                                            setName(e.target.value)
                                            values.name = e.target.value;
                                            if (isSaveClick === true) {
                                                setErrors(validation(values));
                                            }
                                        }}
                                    />
                                    {/* <span className='text-red px-2 text-tiny'>{errors.name}</span> */}
                                </div>

                                <div className='pt-4'>
                                    <label className="font-base  text-white sm:text-sm text-base"> Email </label>
                                    <input type="email"
                                        value={values.email}
                                        id='email'
                                        placeholder={`${errors.email ? "*Email id is required!!" : ""}`}
                                        className={`${errors.email ? "placeholder:text-[#bb4848]" : ""} borderWhiteColor px-4 focus:outline-none focus:border-[#555352] w-[100%] h-[40px] sm:h-[30px] md:h-[30px] mdsm:h-[30px] lg:h-[40px] sm:text-sm placeholder:text-text-colo ${errors.email ? "bor1" : "border-black"} bor px-3 my-1`}
                                        onChange={(e) => {
                                            setEmail(e.target.value)
                                            values.email = e.target.value;
                                            if (isSaveClick === true) {
                                                setErrors(validation(values));
                                            }
                                        }}
                                    />
                                    {/* <span className='text-red px-2 text-tiny'>{errors.email}</span> */}
                                </div>
                                <div className='pt-4'>
                                    <label className="font-base  text-white sm:text-sm text-base"> Mobile Number </label>
                                    <input
                                        type="text"
                                        value={values.phone}
                                        id='phone'
                                        placeholder={`${errors.phone ? "*Mobile number is Required!!" : ""}`}
                                        className={`${errors.phone ? "placeholder:text-[#bb4848]" : ""} borderWhiteColor px-4 focus:outline-none focus:border-[#555352] w-[100%] h-[40px] sm:h-[30px] md:h-[30px] mdsm:h-[30px] lg:h-[40px] sm:text-sm placeholder:text-text-colo ${errors.email ? "bor1" : "border-black"} bor px-3 my-1`}
                                        onChange={(e) => {
                                            setPhone(e.target.value)
                                            values.phone = e.target.value;
                                            if (isSaveClick === true) {
                                                setErrors(validation(values));
                                            }
                                        }}
                                        maxLength={10}
                                        minLength={10}
                                        onKeyPress={(e) => { if (!/^[0-9]/.test(e.key)) { e.preventDefault() } }}
                                    />
                                    {/* <span className='text-red px-2 text-tiny'>{errors.phone}</span> */}
                                </div>
                                <div className="flex justify-center mt-3">
                                    <button onClick={(e) => handlesubmit(e)} className="bg-secondary hover:bg-white hover:text-secondary transition07  text-white py-2 mt-1  px-4 w-[60%] sm:text-sm">
                                        Submit
                                    </button><br />
                                </div>
                                {send ? <div id='Suceffssfully' className='text-green text-center mt-3'>Submitted Sucessfully</div> : null}
                                {errorsend ? <div id='error' className='text-red text-center mt-3'>Error in Submitting Please Try Again later</div> : null}


                            </form>

                        </div>

                        {/* <div className="py-6 px-14 border-evevnt bg-white border-bottom">
                            <div className="mt-1">
                                <label className="font-base text-font-color sm:text-sm text-base"> Name </label>
                                <div>
                                    <input type="text" value={values.name} id='name' className={`rounded-md h-[36px] w-[100%] ${errors.name?"bor1":"border-black"} bor px-3 my-1`}
                                     onChange={(e) => {
                                        setName(e.target.value)
                                        values.name = e.target.value;
                                        if (isSaveClick == true) {
                                            setErrors(validation(values));
                                        }
                                    }}
                                    />
                                    <span className='text-red px-2 text-tiny'>{errors.name}</span>
                                </div>
                            </div>
                            <div className="mt-2">
                                <label className="font-base text-font-color" > Email </label>
                                <div>
                                    <input type="email" value={values.email} id='email' className={`rounded-md h-[36px] w-[100%]  ${errors.email?"bor1":"border-black"} bor px-3 my-1`}
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                        values.email = e.target.value;
                                        if (isSaveClick == true) {
                                            setErrors(validation(values));
                                        }
                                    }}
                                    />
                                    <span className='text-red px-2 text-tiny'>{errors.email}</span>
                                </div>
                            </div>
                            <div className="mt-2">
                                <label className="font-base text-font-color "> Mobile Number </label>
                                <div>
                                    <input type="text" value={values.phone} id='phone' className={`rounded-md h-[36px] w-[100%] ${errors.phone?"bor1":"border-black"} my-1 px-3 bor`}
                                    onChange={(e) => {
                                        setPhone(e.target.value)
                                        values.phone = e.target.value;
                                        if (isSaveClick == true) {
                                            setErrors(validation(values));
                                        }
                                    }}
                                    maxLength={10}
                                    minLength={10}
                                    onKeyPress={(e) => { if (!/^[0-9]/.test(e.key)) { e.preventDefault() } }}
                                    />
                                     <span className='text-red px-2 text-tiny'>{errors.phone}</span>
                                </div>
                            </div>
                            <div className="flex justify-center mt-3">
                                <button onClick={(e) => handlesubmit(e)} className="mt-3 mb-0 cursor-pointer bg-secondary hover:bg-white hover:text-secondary border-2 transition duration-150  border-radius font-medium  w-[40%] text-white  text-center px-1 py-2">
                                    Submit
                                </button><br/>
                            </div>
                            {send? <div id='Suceffssfully' className='text-green text-center mt-3'>Submitted Sucessfully</div>:null}
                            {errorsend? <div id='error' className='text-red text-center mt-3'>Error in Submitting Please Try Again later</div>:null}
                            <div id='error' className='text-red text-center mt-3'></div>
                        </div> */}

                    </div>
                </div>
            </div>
        </div>
    )
}
