import { useState, useEffect } from 'react';
// import ReactDOM from "react-dom";
// import Countdown from "react-countdown";
import HeaderSlider from "../Hub-Components/Home-Component/HeaderSlider";
// import Navbar from "../Hub-Components/Navbar";
import AboutYuvaPage from "../Hub-Components/Home-Component/AboutYuvaPage";
import Employability from "../Hub-Components/Home-Component/Employability";
// import FooterSection from "../Hub-Components/Footer";
import YuvaProvide from "../Hub-Components/Home-Component/YuvaProvide";
import Launch from "../Asset/Images/Launch.png"
// import {renderer} from '../Hub-Components/CountDown'
import Modal from 'react-modal';
import Welcomepic from '../Asset/RVSYUVA SCHOLARSHIP English.jpg'


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

// Modal.setAppElement('#pop');

function Homepage() {
    const [counter, setCounter] = useState(0);
    const [welcome, setWelcome] = useState(false)
    // const [refreshKey, setRefreshKey] = useState(0);
    const [modalIsOpen, setIsOpen] = useState(true);

    // function openModal() {
    //     setIsOpen(true);
    // }

    function closeModal() {
        setIsOpen(false);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setCounter((prevCounter) => prevCounter - 1);
        }, 1000);

        return () => {
            clearInterval(interval);
        }

    }, []);

    if (counter === 0) {
        setWelcome(true)
        setCounter(null)
    }

    modalIsOpen ? document.body.style.overflow = 'hidden': document.body.style.overflow = 'auto'

    return (
        <div >
            {welcome ? <div className='scale-up-bottom'>
                {/* <Navbar /> */}
                <HeaderSlider />
                <AboutYuvaPage />
                <YuvaProvide />
                <Employability />
                <Modal
                    isOpen={modalIsOpen}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="RVS YUVA"
                >
                    <div className='' >
                        <a href='/scholarships'>

                
                        {/* <span className='cursor-pointer absolute right-7 top-0' onClick={closeModal}>&times;</span> */}
                        <img src={Welcomepic} className='w-[400px]' alt="Banner"/>
                        </a>
                    </div>
                </Modal>
                {/* <FooterSection /> */}
            </div> : <div className='h-[100vh]'>
                <div className='luanchBackground relative'>
                    <img src={Launch} alt="launch" className="launch-rocket sm:hidden mdsm:hidden lg:block" />
                    <div className='center h-[100vh] launch-height'>
                        <div className='block '>
                            <div className='launch sm:text-center text-white text-[100px] mdsm:text-[50px] lg:text-[100px] tracking-widest sm:text-[50px] '>RVS <span className='launch text-white text-[100px] mdsm:text-[50px] lg:text-[100px]  tracking-widest sm:text-[50px]'>Y</span>uva Launch</div>
                            <div className='center'>{welcome ? null : <div className=" kalam-font text-[260px] mdsm:text-[150px] lg:text-[260px] sm:text-[100px] text-white "> {counter}</div>} </div>
                        </div>
                    </div>
                    <div className='abosolute bottom-0 sm:text-center TechfromTech w-[100%]  center'> <div className='text-white  tracking-widest py-2 text-h1 sub-heading'>@TransformTech PVT,Ltd.</div> </div>
                </div>
            </div>}

        </div>
    )
}
export default Homepage 