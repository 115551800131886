import React from "react";
import AboutusImage from "../../Asset/Images/Home/about-Yuva.jpg"
function AboutUspage() {
    return (
        <div>
            <div className='container mx-auto py-16'>
                <h2 className="text-primary sm:text-h2 sm:text-center sub-heading text-h1 pb-3">ABOUT  <span className="text-secondary sm:text-h2 sm:text-center sub-heading text-h1 pb-3">RVS YUVA</span> </h2>
                <div className="w-[100%] flex pt-4 sm:block mdsm:block lg:flex">
                    <div className="w-[40%] sm:w-[100%] sm:px-4  mdsm:w-[100%] lg:w-[40%]">
                        <img src={AboutusImage} />
                    </div>
                    <div className="w-[60%] px-10 sm:w-[100%] mdsm:w-[100%] sm:px-4  lg:pt-0 mdsm:pt-8 mdsm:px-0 lg:px-10 lg:w-[60%]">
                        <p className="text-text-color mdsm:px-4 lg:px-0 sm:pt-4 sm:text-sm leading-[26px] text-base pb-4">
                            With a new initiative called RVS YUVA SULUR, we are committed to empowering
                            young people in and around Coimbatore by enhancing their employability skills.
                            The program is designed to help talented young people gain skills that will make
                            them more employable within the RVS campus and beyond. Our focus is on individuals <strong>between the ages of
                                13 and 21</strong>, and we aim to achieve this goal without any boundaries.
                        </p>
                        <p className="text-text-color mdsm:px-4 lg:px-0 sm:text-sm leading-[26px] text-base pb-4">
                            RVS Yuva is an initiative that aims to empower every deserving  first-generation under Privileged learner by providing them with
                            opportunities for necessary training, knowledge incubation, talent and skill
                            development, and career opportunities through training and interventions in both
                            academics and non-academics. A network of mentors with experience in their respective
                            fields will assist you in developing your skills, allowing you to identify and
                            continue pursuing better
                            opportunities sooner. We'll hold your hand and lead you on the right path.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUspage