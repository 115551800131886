import React from "react";
// import CommingSoon from "../Hub-Components/CommingSoon";
import ContactMap from "../Hub-Components/Contact-Components/ContactMap";
// import FooterSection from "../Hub-Components/Footer";
// import Navbar from "../Hub-Components/Navbar";
import SubHeader from "../Hub-Components/SubHeader";
function ContactUs(){
    return(
        <div>
            {/* <CommingSoon/> */}
            <SubHeader breadcrumbs="Contact us" first_breadcrumbs_link=""/>
            <ContactMap/>

        </div>
    )
}
export default ContactUs