import React from "react";
import CommingSoonImage from "../Asset/Images/comming-soon.png"

function CommingSoon(){
    return(
        <div>
            <img src={CommingSoonImage} alt="CommingSoonImage" className=""/>

        </div>
    )
}
export default CommingSoon