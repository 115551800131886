import React from "react";
import Singer from "../../Asset/Images/Home/singer.jpg"
import PhotoGrapher from "../../Asset/Images/Home/photoGrapher.jpg"
import Film from "../../Asset/Images/Home/Film.png"
import Sports from "../../Asset/Images/Home/Sports.jpg"
import Rhythm from "../../Asset/Images/Home/Rhythm.png"
import Artist from "../../Asset/Images/Home/artist.png"

import { VscDebugBreakpointLogUnverified } from "react-icons/vsc";
function YuvaProvide() {
    return (
        <div className=" pt-4 yuvaforyou">
            <div className="container pt-8 mx-auto">
                <div className="flex justify-center pb-4">
                    <h2 className="text-secondary sm:text-h2 sm:text-center sub-heading text-h1 pb-3">YUVA
                        <span className="text-primary sm:text-h2 sm:text-center sub-heading text-h1 pb-3"> FOR YOU</span> </h2>
                </div>
            </div>
            <div className="container  mx-auto">
                <div className="w-[100%] flex flex-wrap sm:block">
                    <div className="w-[32%] overflow-none sm:w-[100%] mdsm:w-[46%] lg:w-[32%] ">
                        <div className="relative group overflow-hidden ">
                            <img src={Singer} alt="Singer" className="w-[100%]  object-cover  h-[550px] grayscale" />
                            <div className="">
                                <div className="hoverColor  element  p-10 h-[100%]  absolute w-[100%] top-0 mdsm:p-4 lg:p-10">
                                    <div className="cardHeader ">
                                        <h4 className="text-white primaryHover sub-heading px-4 text-center absolute top-[15%]  tracking-wider py-2 text-[20px] sm:text-[18px]">RVS Yuva Melodies</h4>
                                    </div>
                                    <div className="cardElemts">
                                        <div className="absolute  top-[40%]">
                                            <div className="w-[100%] pb-4 flex">
                                                <div className="w-[5%] mt-2">
                                                    <VscDebugBreakpointLogUnverified className="text-primary text-h4" />
                                                </div>
                                                <div className="w-[95%]">
                                                    <p className="text-white flex pl-4 pr-4 text-[16px] tracking-wider leading-[40px] sm:text-sm">RVS YUVA Sulur started YUVA Melodies for upcoming singers.</p>
                                                </div>
                                            </div>

                                            <div className="w-[100%] pb-4 flex">
                                                <div className="w-[5%] mt-2">
                                                    <VscDebugBreakpointLogUnverified className="text-primary text-h4" />
                                                </div>
                                                <div className="w-[95%]">
                                                    <p className="text-white flex pl-4 pr-4 text-[16px] tracking-wider leading-[40px] sm:text-sm">Vocal and instruments training in classical and semi classical music.  </p>
                                                </div>
                                            </div>

                                            <div className="w-[100%] pb-4 flex">
                                                <div className="w-[5%] mt-2">
                                                    <VscDebugBreakpointLogUnverified className="text-primary text-h4" />
                                                </div>
                                                <div className="w-[95%]">
                                                    <p className="text-white flex pl-4 pr-4 text-[16px] tracking-wider leading-[40px] sm:text-sm">Training period of 11 months.</p>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>


                            </div>

                        </div>
                    </div>
                    <div className="w-[32%] overflow-none sm:w-[100%] mdsm:w-[46%] lg:w-[32%] ">
                        <div className="relative group overflow-hidden ">
                            <img src={PhotoGrapher} alt="PhotoGrapher" className="w-[100%] object-cover  h-[550px] grayscale" />
                            <div className="">
                                <div className="hoverColor  element p-10 h-[100%]  absolute w-[100%] top-0 mdsm:p-4 lg:p-10">
                                    <div className="cardHeader">
                                        <h4 className="text-white primaryHover sub-heading px-4 text-center absolute top-[15%]  tracking-wider py-2 text-[20px] sm:text-[18px]">RVS Yuva CAPTURE </h4>
                                    </div>
                                    <div className="cardElemts">
                                        <div className="absolute top-[40%]">

                                            <div className="w-[100%] pb-4 flex">
                                                <div className="w-[5%] mt-2">
                                                    <VscDebugBreakpointLogUnverified className="text-primary text-h4" />
                                                </div>
                                                <div className="w-[95%]">
                                                    <p className="text-white flex pl-4 pr-4 text-[16px] tracking-wider leading-[40px] sm:text-sm">RVS YUVA Capture is for the budding photographers.</p>
                                                </div>
                                            </div>

                                            <div className="w-[100%] pb-4 flex">
                                                <div className="w-[5%] mt-2">
                                                    <VscDebugBreakpointLogUnverified className="text-primary text-h4" />
                                                </div>
                                                <div className="w-[95%]">
                                                    <p className="text-white flex pl-4 pr-4 text-[16px] tracking-wider leading-[40px] sm:text-sm">The countless innovation of capturing photos</p>
                                                </div>
                                            </div>

                                            <div className="w-[100%] pb-4 flex">
                                                <div className="w-[5%] mt-2">
                                                    <VscDebugBreakpointLogUnverified className="text-primary text-h4" />
                                                </div>
                                                <div className="w-[95%]">
                                                    <p className="text-white flex pl-4 pr-4 text-[16px] tracking-wider leading-[40px] sm:text-sm">It will be launched soon.</p>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>


                            </div>

                        </div>
                    </div>
                    <div className="w-[32%] overflow-none sm:w-[100%] mdsm:w-[46%] lg:w-[32%] ">
                        <div className="relative group overflow-hidden ">
                            <img src={Film} alt="Film" className="w-[100%] object-cover  h-[550px] grayscale" />
                            <div className="">
                                <div className="hoverColor  element  p-10 h-[100%]  absolute w-[100%] top-0 mdsm:p-4 lg:p-10">
                                    <div className="cardHeader">
                                        <h4 className="text-white primaryHover sub-heading px-4 text-center absolute top-[15%]  tracking-wider py-2 text-[20px] sm:text-[18px]">RVS YUVA SHORT FILM</h4>
                                    </div>
                                    <div className="cardElemts">

                                        <div className="absolute top-[40%]">

                                            <div className="w-[100%] pb-4 flex">
                                                <div className="w-[5%] mt-2">
                                                    <VscDebugBreakpointLogUnverified className="text-primary text-h4" />
                                                </div>
                                                <div className="w-[95%]">
                                                    <p className="text-white flex pl-4 pr-4 text-[16px] tracking-wider leading-[40px] sm:text-sm">RVS YUVA Short films for who want to become a film star</p>
                                                </div>
                                            </div>

                                            <div className="w-[100%] pb-4 flex">
                                                <div className="w-[5%] mt-2">
                                                    <VscDebugBreakpointLogUnverified className="text-primary text-h4" />
                                                </div>
                                                <div className="w-[95%]">
                                                    <p className="text-white flex pl-4 pr-4 text-[16px] tracking-wider leading-[40px] sm:text-sm">It is the ideal starting point for turning into a great director.</p>
                                                </div>
                                            </div>

                                            <div className="w-[100%] pb-4 flex">
                                                <div className="w-[5%] mt-2">
                                                    <VscDebugBreakpointLogUnverified className="text-primary text-h4" />
                                                </div>
                                                <div className="w-[95%]">
                                                    <p className="text-white flex pl-4 pr-4 text-[16px] tracking-wider leading-[40px] sm:text-sm">It will be launched soon.</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>

                    <div className="w-[32%] overflow-none sm:w-[100%] mdsm:w-[46%] lg:w-[32%] ">
                        <div className="relative group overflow-hidden overflow-none">
                            <img src={Sports} alt="Sports" className="w-[100%] object-cover  h-[550px] grayscale" />
                            <div className="">
                                <div className="hoverColor element p-10 h-[100%]  absolute w-[100%] top-0 mdsm:p-4 lg:p-10">
                                    <div className="cardHeader">
                                        <h4 className="text-white  primaryHover sub-heading px-4 text-center absolute top-[15%]  tracking-wider py-2 text-[20px]">RVS YUVA SPORTS</h4>
                                    </div>
                                    <div className="cardElemts">
                                        <div className="absolute top-[40%]">

                                            <div className="w-[100%] pb-4 flex">
                                                <div className="w-[5%] mt-2">
                                                    <VscDebugBreakpointLogUnverified className="text-primary text-h4" />
                                                </div>
                                                <div className="w-[95%]">
                                                    <p className="text-white flex pl-4 pr-4 text-[16px] tracking-wider leading-[40px] sm:text-sm">RVS YUVA SPORTS is for students to enhance their sporting skills.</p>
                                                </div>
                                            </div>

                                            <div className="w-[100%] pb-4 flex">
                                                <div className="w-[5%] mt-2">
                                                    <VscDebugBreakpointLogUnverified className="text-primary text-h4" />
                                                </div>
                                                <div className="w-[95%]">
                                                    <p className="text-white flex pl-4 pr-4 text-[16px] tracking-wider leading-[40px] sm:text-sm">Training for different levels of matches will be provided.</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                    <div className="w-[32%] overflow-none sm:w-[100%] mdsm:w-[46%] lg:w-[32%] ">
                        <div className="relative group overflow-hidden overflow-none">
                            <img src={Rhythm} alt="Rhythm" className="w-[100%] object-cover  h-[550px] grayscale" />
                            <div className="">
                                <div className="hoverColor  element p-10 h-[100%]  absolute w-[100%] top-0 mdsm:p-4 lg:p-10">
                                    <div className="cardHeader">
                                        <h4 className="text-white primaryHover sub-heading px-4 text-center absolute top-[15%]  tracking-wider py-2 text-[20px] sm:text-[18px]">RVS YUVA RHYTHM</h4>
                                    </div>
                                    <div className="cardElemts">
                                        <div className="absolute top-[40%]">

                                            <div className="w-[100%] pb-4 flex">
                                                <div className="w-[5%] mt-2">
                                                    <VscDebugBreakpointLogUnverified className="text-primary text-h4" />
                                                </div>
                                                <div className="w-[95%]">
                                                    <p className="text-white flex pl-4 pr-4 text-[16px] tracking-wider leading-[40px] sm:text-sm">RVS YUVA RHYTHM is exclusively for dance enthusiasts.</p>
                                                </div>
                                            </div>

                                            <div className="w-[100%] pb-4 flex">
                                                <div className="w-[5%] mt-2">
                                                    <VscDebugBreakpointLogUnverified className="text-primary text-h4" />
                                                </div>
                                                <div className="w-[95%]">
                                                    <p className="text-white flex pl-4 pr-4 text-[16px] tracking-wider leading-[40px] sm:text-sm">  Choreographies done on popular genres</p>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                    <div className="w-[32%] overflow-none sm:w-[100%] mdsm:w-[46%] lg:w-[32%] ">
                        <div className="relative group overflow-hidden overflow-none">
                            <img src={Artist} alt="Artist" className="w-[100%] object-cover  h-[550px] grayscale" />
                            <div className="">
                                <div className="hoverColor element  p-10 h-[100%]  absolute w-[100%] top-0 mdsm:p-4 lg:p-10">
                                    <div className="cardHeader">
                                        <h4 className="text-white primaryHover sub-heading px-4 text-center absolute top-[15%]  tracking-wider py-2 text-[20px] sm:text-[18px]">RVS YUVA ARTISTRY</h4>
                                    </div>
                                    <div className="cardElemts">
                                        <div className="absolute top-[40%]">

                                            <div className="w-[100%] pb-4 flex">
                                                <div className="w-[5%] mt-2">
                                                    <VscDebugBreakpointLogUnverified className="text-primary text-h4" />
                                                </div>
                                                <div className="w-[95%]">
                                                    <p className="text-white flex pl-4 pr-4 text-[16px] tracking-wider leading-[40px] sm:text-sm">YUVA ARTISTRY is for art and artists.</p>
                                                </div>
                                            </div>

                                            <div className="w-[100%] pb-4 flex">
                                                <div className="w-[5%] mt-2">
                                                    <VscDebugBreakpointLogUnverified className="text-primary text-h4" />
                                                </div>
                                                <div className="w-[95%]">
                                                    <p className="text-white flex pl-4 pr-4 text-[16px] tracking-wider leading-[40px] sm:text-sm">It is the ideal starting point for turning into a great director.</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>

                        <div className="py-4"></div>

                    </div>


                </div>
            </div>
            <div className="">


            </div>

        </div>
    )
}
export default YuvaProvide