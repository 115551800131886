import React from "react"
// import CommingSoon from "../Hub-Components/CommingSoon"
// import FooterSection from "../Hub-Components/Footer"
// import Navbar from "../Hub-Components/Navbar"
import ScholarshipBroucher from "../Hub-Components/Scholar-components/ScholarShipBroucher"
function Pedagogy(){
    return(
        <div>
       {/* <Navbar/> */}
       <ScholarshipBroucher/>
       {/* <CommingSoon/> */}
       {/* <FooterSection/> */}

        </div>
    )
}
export default Pedagogy