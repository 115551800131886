export const states = [
  {
    id: 0,
    districtname: "COIMBATORE",
    townname: [
      "ANNUR",
      "ANAMALAI",
      "IRUGUR",
      "KOTTUR",
      "SIRUMUGAI",
      "SULUR",
      "PERIANAICKEN PALAYAM",
      "VELLALORE",
      "VETTAIKARANPUDUR",
      "ZAMINUTHUKULI",
      "OTHAKKALMANDAPAM",
      "ODAYAKULAM",
      "KANNAMPALAYAM",
      "KINATHUKADAVU",
      "SAMATHUR",
      "SARCARSAMAKULAM",
      "SULESWARANPATTI",
      "DHALIYUR",
      "N.S.N.PALAYAM",
      "PALLAPALAYAM",
      "POOLUVAPATTI",
      "PERIANEGAMAM",
      "PERUR",
      "VEDAPATTI",
      "VEERAPANDI",
      "ALANDURAI",
      "IDIGARAI",
      "ETTIMADAI",
      "CHETTIPALAYAM",
      "THIRUMALAYAMPALAYAM",
      "THENKARAI",
      "THONDAMUTHUR",
      "MOPPERIPALAYAM",
    ]
  },
  {
    id: 1,
    districtname: "TIRUPPUR",
    townname: [
      "AVANASI",
      "CHINNAKKAMPALAYAM",
      "DHALI",
      "KANIYUR",
      "KANNIVADI",
      "KOLATHUPALAYAM",
      "KOMARALINGAM",
      "KUNNATHUR",
      "MADATHUKULAM",
      "MULANUR",
      "MUTHUR",
      "RUDRAWATHI",
      "SAMALAPURAM",
      "SANGARAMANALLUR",
      "THIRUMURUGANPOONDI",
      "UTHUKULI",
      "PALLADAM",
      "TIRUPPUR"
    ]
  },
  {
    id: 2,
    districtname: "KANNUR",
    townname: [
      "THALASSERY",
      "TALIPARAMBA",
      "PAYYANUR",
      "IRITTY",
      "KANNUR",
    ]
  },
  {
    id: 3,
    districtname: "KOZHIKODE",
    townname: [
      "THAMARASSERY",
      "KOYILANDY",
      "VATAKARA",
      "KOZHIKODE",
    ]
  },
  {
    id: 3,
    districtname: "PALAKKAD",
    townname: [
      "ALATHOOR",
      "CHITTUR",
      "PATTAMBI",
      "OTTAPPALAM",
      "MANNARKKAD",
      "ATTAPPADY",
      "PALAKKADU",
    ]
  },
];
