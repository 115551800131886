import React from 'react'
// import Banner from "../../Asset/Images/Home/banner.png"
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../../CSS/Slick.css"
// import YUVABANNER from "../../Asset/Images/Home/yuva-banner.png"
import RVSYuvaBanner from "../../Asset/Images/Home/rvs-yuva-banner.webp"


export default function HeaderSlider() {

  const options = {
    items: 1,
    nav: true,
    navText: ["<div className='nav-btn prev-slide left-arrow arrow-button'><i class='arrow-left fa-solid fa-angle-left'></i></div>", "<div className='nav-btn next-slide'><i class='arrow-left fa-solid fa-angle-right'></i></div>"],
    rewind: true,
    autoplay: true,
    dots: false,
  };

  return (
    <div>
      <OwlCarousel className="owl-theme" {...options}>
      <div className="item">
          <div className='relative-banner bg-primaryHover h-[100%] w-[100%]'>
            <img src={RVSYuvaBanner} alt="New" className="HeroSection-Image object-cover w-[100%]" />
            {/* <div className='top-0 absolute h-[100%] w-[100%]'>
              <div className='container mx-auto'>
                <div class="centered bg-primaryHover w-[70%] ">
                  <div className='sub-heading text-[36px] sm:text-[18px] text-left text-primary'>RVS Yuva is an initiative that aims to empower</div>
                  <p className='w-[50%] mdsm:w-[100%] lg:w-[50%] sm:w-[100%] text-left leading-[26px] sm:text-sm pt-2'>With a new initiative called RVS YUVA SULUR, we are committed to empowering young people in and around Coimbatore by enhancing their employability skills.</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>

      
      </OwlCarousel>
    </div>
  )
}