import React from 'react'
// import Navbar from '../Hub-Components/Navbar'
import SubHeader from '../Hub-Components/SubHeader'
import YuvaMelodiesss from '../Hub-Components/Yuva-Melodies/YuvaMelodiesss'

export default function YuvaMelodies() {
  return (
    <div>
        {/* <Navbar /> */}
        <SubHeader breadcrumbs="Yuva Melodies" first_breadcrumbs_link="yuvamelodies"/>
        <YuvaMelodiesss/>
    </div>
  )
}
