import React from "react";
import goalsImage from "../../Asset/Images/About/goals.jpg"
function Goals() {

    return (
        <div className="container mx-auto py-16">
            <div className='center'>

            </div>
            <div className='w-[100%] flex mdsm:block lg:flex sm:block pb-8'>
                <div className='w-[60%] sm:w-[100%] mdsm:w-[100%]  lg:w-[60%]'>
                    <div className='px-10 sm:px-4'>

                        <div className='center'>

                            <h2 className="text-primary sm:text-h2 sm:text-center sub-heading text-h1 pb-3">GO<span className="text-secondary sub-heading text-h1 sm:text-h2">ALS</span> </h2>
                        </div>

                        <div className='flex  w-[100%] px-10 py-4 sm:px-4 group transition07'>
                            <div className='w-[15%]'>
                                <div className='transition07 p-4 group-hover:bg-primary group-hover:border-primary bg-secondary border-4 border-secondary w-[20px] h-[40px] sm:w-[10px] sm:w-[30px] sm:text-h5 text-white center text-h3'>1
                                </div>
                            </div>
                            <div className='w-[85%]'>
                                <div className=' transition07 text-text-color group-hover:text-secondary sm:text-h6 text-h5 py-3'>
                                    Talent recognition without borders
                                </div>
                                <div className='text-text-color leading-[26px] sm:text-sm'>
                                    Because it is essential in achieving the big goals. Helping to bring a brighter future for the Youth
                                </div>
                            </div>
                        </div>

                        <div className='flex w-[100%] sm:px-4 px-10 py-4 group transition07'>
                            <div className='w-[15%]'>
                                <div className='transition07 p-4 group-hover:bg-primary group-hover:border-primary bg-secondary border-4 border-secondary w-[20px] h-[40px] sm:w-[10px] sm:w-[30px] sm:text-h5 text-white center text-h3'>2
                                </div>
                            </div>
                            <div className='w-[85%]'>
                                <div className='transition07 text-text-color group-hover:text-secondary sm:text-h6 text-h5 py-3'>
                                    Empowerment of Youth for a better community
                                </div>
                                <div className='text-text-color leading-[26px] sm:text-sm'>
                                    These three goals are crucial in working towards a better future for the youth by recognizing their talents regardless of geographical boundaries
                                </div>
                            </div>
                        </div>

                        <div className='flex w-[100%] px-10 sm:px-4 py-4 group transition07'>
                            <div className='w-[15%]'>
                                <div className='transition07 p-4 group-hover:bg-primary group-hover:border-primary bg-secondary border-4 border-secondary w-[20px] h-[40px] sm:w-[10px] sm:w-[30px] sm:text-h5 text-white center text-h3'>3
                                </div>
                            </div>
                            <div className='w-[85%]'>
                                <div className='transition07 text-text-color group-hover:text-secondary sm:text-h6 text-h5 py-3'>
                                    Better employability for Youth
                                </div>
                                <div className='text-text-color leading-[26px] sm:text-sm'>
                                    providing them with the necessary skills to contribute positively to their communities, and improving their career prospects.

                                </div>
                            </div>
                        </div>


                    </div>



                </div>
                <div className='w-[40%] sm:w-[100%] mdsm:w-[100%]  lg:w-[40%]'>
                    <img src={goalsImage} className="mx-auto" />

                </div>

            </div>

        </div>
    )
}
export default Goals