import React from 'react'
import Sri from '../../Asset/Images/srinivas.png'

export default function Design() {
  return (
    <div>
        <div className='flex'>
            <div className='ploy'>
                <img src={Sri} alt="Sri"/>
            </div>

            <div className=''>
                <p>asdfghjkitwedghhnvnhfjdgiprh</p>
                <p>asdfghjkitwedghhnvnhfjdgiprh</p>
                <p>asdfghjkitwedghhnvnhfjdgiprh</p>
                <p>asdfghjkitwedghhnvnhfjdgiprh</p>
                <p>asdfghjkitwedghhnvnhfjdgiprh</p>
                <p>asdfghjkitwedghhnvnhfjdgiprh</p>
                <p>asdfghjkitwedghhnvnhfjdgiprh</p>
                <p>asdfghjkitwedghhnvnhfjdgiprh</p>
                <p>asdfghjkitwedghhnvnhfjdgiprh</p>

            </div>
        </div>
    </div>
  )
}
