import React from "react";
import Banner from "../Asset/Images/banners.jpg"
import { HiHome } from "react-icons/hi2";
import { Link } from "react-router-dom";

function SubHeader({breadcrumbs},{first_breadcrumbs_link}){
    return(
        <div>
            <div className="relative ">
 
            <img src={Banner} alt="Banner" className="h-[70vh] object-cover w-[100%]"/>
            <div className="absolute top-0 w-[100%] h-[100%] bg-blueBackground">
            <div>
                <div className="flex absolute sub-banner py-2 pr-4">
                    <div className="flex 
                    ">
                        <a href="/">
                        <HiHome className="text-white	 my-2 ml-4 text-[24px]" />
                        </a>
                    <Link to={first_breadcrumbs_link}>
                    <div className="sub-heading tracking-wider px-2 pt-0.5 text-white text-[24px]">
                    - {breadcrumbs}
                    </div>
                    </Link>
                   
                    </div>
                    
                </div>

            </div>

            </div>
               
            </div>
        </div>
    )
}
export default SubHeader
