import React from "react";
import Employabilityimage from "../../Asset/Images/Home/Employability.jpg"
function Employability() {
    return (
        <div className="py-16">
            <div className="container mx-auto">
                <div className=" pb-4">
                    <div className="flex justify-center">
                        <h2 className="text-secondary sm:text-h2 sm:text-center sub-heading text-h1 pb-3">Employability
                            <span className="text-primary sm:text-h2 sm:text-center sub-heading text-h1 pb-3"> of Youth</span> </h2>
                    </div>
                    <p className="text-text-color leading-[26px] sm:text-sm px-6">The RVS Yuva Sulur not only focus on
                        extracurricular activities. It also offers students a
                        roadmap that offers fair and impartial opportunities for
                        all students, along with the special training that will
                        help them equip the skills that will transform them from
                        graduates into industry-ready professionals both in academics and non-academics.
                    </p>
                </div>
                <div className="w-[100%] flex px-6 pb-10" >
                    <div className="w-[50%] sm:w-[100%]">
                    <img src={Employabilityimage} alt="Employabilityimage" className="w-[100%] sm:w-[100%]"/>
                    </div>
                    <div className="w-[50%] sm:hidden relative">
                    <img src={Employabilityimage} alt="Employabilityimage" className="w-[100%]"/>
                    </div>

                </div>

            </div>
        </div>
    )
}
export default Employability  