import React from "react";
import Srinivasan from "../../Asset/Images/About/Mentors/Srinivasan-Doraiswamy.png"
import SudharakOlwe from "../../Asset/Images/About/Mentors/Sudharak-Olwe.png"
import SreerajSahajan from "../../Asset/Images/About/Mentors/Sreeraj-Sahajan.png"
import LakshmiEnnappaadam from "../../Asset/Images/About/Mentors/Lakshmi-Ennappaadam.png"
import Football from "../../Asset/Images/About/Mentors/Football.png"


import Tabs, { Tab } from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
function Mentor() {

    return (
        <div className='bg-background'>
            <div className='container mx-auto py-16  px-10'>
                <h2 className="text-primary sm:text-h3 sub-heading text-center text-h1 pb-3">MENTOR &<span className="text-secondary sm:text-h3 sub-heading text-h1"> PARTNERS</span> </h2>

                <div className='pt-4 pb-6'>
                <Tabs
                    activeTab="1"
                    className=""
                    ulClassName=""
                    activityClassName="bg-success"
                    onClick={(event, tab) => console.log(event, tab)}
                >
                     <Tab title="Srinivasan Doraiswamy" className="mr-3">
                        <div className="mt-3">
                            <div className="w-[100%] flex sm:block mdsm:block lg:flex">
                                <div className="w-[30%] sm:w-[100%] mdsm:w-[100%] p-5 lg:w-[30%]">
                                    <img src={Srinivasan} alt="Srinivasan" className="w-[100%] lg:w-[30%] mdsm:w-[50%] lg:w-[100%] mdsm:mx-auto object-cover h-[450px]" />
                                </div>
                                <div className="w-[60%] p-6 sm:w-[100%] mdsm:w-[100%] lg:w-[60%]">
                                    <p className='text-secondary text-h2 tracking-wider px-6 sm:pt-2 sm:px-2 pt-8 sub-heading'>Srinivasan Doraiswamy</p>
                                    <p className='text-text-color leading-[26px] sm:pt-2 pt-6 sm:px-2 px-6'>
                                        Srinivasan Doraiswamy, also known as Srinivas, is an Indian playback singer who has performed over 2000 songs in Tamil, Malayalam, Telugu, Kannada, and Hindi. He has also worked as an independent music composer on films and private records. He has been a judge for various reality singing shows. He had won many awards and gained recognition. In 2006, the Tamil Nadu government bestowed the Kalaimamani title on him and the Kerala State Award for the song "Baansuri" from the film "Rathri Mazha."
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab title="Sudharak Olwe" className="mr-3">
                        <div className="mt-3">
                            <div className="w-[100%] flex sm:block mdsm:block lg:flex">
                                <div className="w-[30%] sm:w-[100%] mdsm:w-[100%] p-5 lg:w-[30%]">
                                    <img src={SudharakOlwe} alt="Sudharak Olwe" className="w-[100%] lg:w-[30%] mdsm:w-[50%] lg:w-[100%] mdsm:mx-auto object-cover h-[450px]" />
                                </div>
                                <div className="w-[60%] p-6 sm:w-[100%] mdsm:w-[100%] lg:w-[60%]">
                                    <p className='text-secondary text-h2 tracking-wider px-6 sm:pt-2 sm:px-2 pt-8 sub-heading'>Sudharak Olwe</p>
                                    <p className='text-text-color leading-[26px] sm:pt-2 pt-6 sm:px-2 px-6'>
                                    Sudharak Olwe has been a documentary photographer based in Mumbai since 1988. He has travelled the length and breadth of the country and witnessed some incredible stories of resilience, courage, and change. Sudharak's photography embodies empathy. His photography transcends all boundaries and tells a moving story about individuals and communities. In 2005, he was one of the four awardees for National Geographic's "All Roads Photography Program." The President of India bestowed the Padma Shri, India's fourth highest civilian award, on Sudharak in 2016.

                                    </p>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab title="Sreeraj Sahajan" className="mr-3">
                        <div className="mt-3">
                            <div className="w-[100%] flex sm:block mdsm:block lg:flex">
                                <div className="w-[30%] sm:w-[100%] mdsm:w-[100%] p-5 lg:w-[30%]">
                                    <img src={SreerajSahajan} alt="Sreeraj Sahajan" className="w-[100%] lg:w-[30%] mdsm:w-[50%] lg:w-[100%] mdsm:mx-auto object-cover h-[450px]" />
                                </div>
                                <div className="w-[60%] p-6 sm:w-[100%] mdsm:w-[100%] lg:w-[60%]">
                                    <p className='text-secondary text-h2 tracking-wider px-6 sm:pt-2 sm:px-2 pt-8 sub-heading'> Sreeraj Sahajan</p>
                                    <p className='text-text-color leading-[26px] sm:pt-2 pt-6 sm:px-2 px-6'>
                                    Sreeraj Sahajan is a Hindustani classical singer and also trainer. He started his career as a stage performer. After that, he became a playback singer in the Indian film industry. Step by step, he enhanced his musical skill. Now he is a music Director and Composer.For six years, he studied Hindustani classical music with Vijay Sursen. He performs high-precision vocal tuning, music arranging, programming, and mixing in a variety of genres. He is an expert at voice-over work and audio editing.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab title="Lakshmi Ennappaadam" className="mr-3">
                        <div className="mt-3">
                            <div className="w-[100%] flex sm:block mdsm:block lg:flex">
                                <div className="w-[30%] sm:w-[100%] mdsm:w-[100%] p-5 lg:w-[30%]">
                                    <img src={LakshmiEnnappaadam} alt="Lakshmi Ennappaadam" className="w-[100%] lg:w-[30%] mdsm:w-[50%] lg:w-[100%] mdsm:mx-auto object-cover h-[450px]" />
                                </div>
                                <div className="w-[60%] p-6 sm:w-[100%] mdsm:w-[100%] lg:w-[60%]">
                                    <p className='text-secondary text-h2 tracking-wider px-6 sm:pt-2 sm:px-2 pt-8 sub-heading'>Lakshmi Ennappaadam</p>
                                    <p className='text-text-color leading-[26px] sm:pt-2 pt-6 sm:px-2 px-6'>
                                    Lakshmi Ennappaadam, a certified professional vocal trainer and lyricist. she is a dual M.A. (master) degree holder in Carnatic music and Hindustani Classical music, is another mentor. She mastered Hindustani and Carnatic music from Akhil Bharatiya Gandharva Mahavidyalaya, Mumbai. She is currently working as a music teacher at Palarivattom Trinetra Arts Academy. She is the founder, director, and CEO at ALMS AL Music school in Kerala.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    

                    <Tab title="Partners" className="mr-3">
                        <div className="mt-3">
                            <div className="w-[100%] flex sm:block mdsm:block lg:flex">
                                <div className="w-[30%] sm:w-[100%] mdsm:w-[100%] p-5 lg:w-[30%]">
                                    <img src={Football} alt="Football" className="w-[100%] lg:w-[30%] mdsm:w-[50%] lg:w-[100%] mdsm:mx-auto object-cover h-[450px]" />
                                </div>
                                <div className="w-[60%] p-6 sm:w-[100%] mdsm:w-[100%] lg:w-[60%]">
                                    <p className='text-secondary text-h2 tracking-wider px-6 sm:pt-2 sm:px-2 pt-8 sub-heading'>Partners</p>
                                    <p className='text-text-color leading-[26px] sm:pt-2 pt-6 sm:px-2 px-6'>
                                    India Khelo Football (IKF) is a non-profit organisation that provides a platform for aspiring football players to chart their own career paths. Its mission is to identify talented young Indian football players and provide them with opportunities to compete on a global scale. Students who enrol will receive a globally recognised PFSA (Professional Football Scouts Association) certificate in talent identification. They facilitate interaction with Premier League scouts and direct learning from them. Premier League scouts lead various workshops, and genuine industry experts provide feedback on your game.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Tab>

                    
                </Tabs>
                </div>
             



            </div>
        </div>
    )
}
export default Mentor