import React from 'react'
// import FooterSection from '../Hub-Components/Footer'
// import Navbar from '../Hub-Components/Navbar'
import ScholarshipBroucher from '../Hub-Components/Scholar-components/ScholarShipBroucher'
// import Brouchre from '../Hub-Components/Scholar-components/Brouchre'
// import Design from '../Hub-Components/Scholar-components/Design'
// import SubHeader from '../Hub-Components/SubHeader'

export default function ScholarShip() {
  return (
    <div>
        {/* <Navbar /> */}
        <ScholarshipBroucher/>
        {/* <SubHeader /> */}
        {/* <Brouchre/>  */}
        {/* <FooterSection /> */}
        
    </div>
  )
}
