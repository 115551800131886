import React from "react";
// import { RiInstagramLine, RiTwitterLine, RiWhatsappLine, RiFacebookLine } from "react-icons/ri";
// import {
//     WhatsappShareButton,
//     TwitterShareButton,
//     FacebookShareButton,
//     LinkedinShareButton,
//   } from "react-share";

//   import { RWebShare } from "react-web-share";
function FooterSection(){
    const date = new Date().getFullYear()
    return(
        <div className="bg-[#232323] bg-fixed py-16 pb-6">
            <div className="container mx-auto">

                <div className="w-[100%] flex sm:flex-wrap sm:px-4 mdsm:flex mdsm:flex-wrap lg:flex pb-4">
                    <div className="w-[32%] sm:w-[100%] mdsm:w-[48%] lg:w-[33%] px-4 ">
                            <h2 className="text-white sub-heading text-[20px] tracking-widest pb-3">About Yuva</h2>

                            <p className="text-white leading-[26px] text-base sm:text-sm ">RVS YUVA SULUR is a social effort with the goal of empowering talent without regard for borders. It guides the youth towards employment in academic and non-academic fields. </p>
                    </div>
                    <div className="w-[32%] sm:w-[100%] mdsm:w-[48%] lg:w-[33%] px-4">
                    <div className='pb-16'>
                            <p className='text-white sub-heading text-[20px] tracking-widest pb-3'>Contact Details</p>
                            <p className='text-white sm:text-sm pb-2 leading-[26px]'>Phone: 0422 2687 421/480/603</p>
                            {/* <p className='text-white sm:text-sm pb-2 leading-[26px]'>Email: info@rvsgroup.com</p> */}
                            <p className='text-white sm:text-sm pb-2 leading-[26px]'>Email: <a href="mailto:scholarships@rvsyuva.org" >scholarships@rvsyuva.org</a></p>

                            
                        </div> 
                    </div>
                    <div className="w-[32%] sm:w-[100%] mdsm:w-[48%] lg:w-[33%] px-4">
                    <p className='text-white sub-heading text-[20px]  tracking-widest pb-3'>Location</p>
                            <p className='text-white sm:text-sm pb-2 leading-[26px]'>242, Trichy Road,Sulur,</p>
                            <p className='text-white sm:text-sm pb-2 leading-[26px]'>Coimbatore – 641 402.Tamilnadu,</p>
                            <p className='text-white sm:text-sm pb-2 leading-[26px]'>South India.</p>
                    </div>
                    {/* <div className="w-[33%] sm:pt-6">
                         <div className="py-1 flex justify-end">
                        <div className="mx-2 py-2 group bg-white cursor-pointer center h-[40px] w-[40px]">
                            <FacebookShareButton
                            title="RVS YUVA"
                            url={"http://rvsyuva.org"}
                            >
                            <RiFacebookLine className="text-secondary text-h5 group-hover:text-primary" />

                            </FacebookShareButton>
                        </div>
                        <div className="mx-2 py-2 group bg-white cursor-pointer center h-[40px] w-[40px]">
                        <RWebShare
                            data={{
                                text: "RVS YUVA",
                                url: "http://rvsyuva.org",
                                title: "RVS YUVA",
                            }}
                            onClick={() => console.log("shared successfully!")}
                            >
                            <RiInstagramLine className="text-secondary text-h5 group-hover:text-primary" />
                            </RWebShare>
                        </div>
                        <div className="mx-2 py-2 group bg-white cursor-pointer center h-[40px] w-[40px]">
                            <TwitterShareButton
                             title="RVS YUVA"
                             url={"http://rvsyuva.org"}
                             >
                            <RiTwitterLine className="text-secondary text-h5 group-hover:text-primary" />
                            </TwitterShareButton>
                        </div>
                        <div className="mx-2 py-2 group bg-white cursor-pointer center h-[40px] w-[40px]">
                        <WhatsappShareButton
                            className=""
                            title="RVS YUVA"
                            url={"http://rvsyuva.org"}
                        >
                            <RiWhatsappLine className="text-secondary text-h5 group-hover:text-primary" />
                        </WhatsappShareButton>
                        </div>
                    </div>
                    </div> */}

                </div>


                    {/* <div className="w-[100%] flex">
                        <div className="">

                        </div>

                    </div> */}
                <h2 className="text-white pt-10 text-center text-[12px]">"Designed, Developed and Maintained By Transform Tech"</h2>
                            <h3 className="text-white pb-3 text-center text-[12px]">A Unit of RVS Educational Trust</h3>
                
           
                    <hr className="h-[1px] text-white"/>
                    <p className="text-center pt-5 text-[12px] text-white">© {date} RVSCAS COIMBATORE. All rights reserved</p>
                
            </div>


        </div>
    )
}
export default FooterSection