// import logo from './logo.svg';
import "./Components/CSS/Components.css"
import "./Components/CSS/Responsive.css"
import './App.css';
// import SubHeader from './Components/Hub-Components/SubHeader';
// import HeaderSection from './Components/Home-page/HeaderSection';
// import Sliders from './Components/Home-page/Slider';
// import Nav from './Components/Home-page/Nav';
// import Aboutyuva from './Components/Hub-Components/Aboutyuva';
// import Count from './Components/Hub-Components/Count';
// import WhatWe from './Components/Hub-Components/WhatWe';
// import Emplobility from './Components/Hub-Components/Emplobility';
// import Footer from './Components/Hub-Components/Footer';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Homepage from './Components/Pages/HomePage';
// import Brouchre from './Components/Pages/Brouchre';
import Pedagogy from './Components/Pages/Pedagogy';
import ActivityClub from './Components/Pages/ActivityClub';
import AboutUs from './Components/Pages/AboutUs';
import ScholarShip from './Components/Pages/ScholarShip';
import EmployabilityPage from './Components/Pages/Employability';
import ContactUs from './Components/Pages/ContactUs';
import Design from './Components/Hub-Components/Scholar-components/Design';
import YuvaMelodies from './Components/Pages/YuvaMelodies';
import PageNotFound from './Components/Hub-Components/Page404/PageNotFound';
import Thankyou from "./Components/Pages/Thankyou";
import Navbar from "./Components/Hub-Components/Navbar";
import FooterSection from "./Components/Hub-Components/Footer";

function App() {
  return (
    <div className="">
      <BrowserRouter>
      <Navbar/>
        <Routes>
          <Route path="/" element={< Homepage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path='/scholarships' element={<ScholarShip />} />
          <Route path="/employability" element={<EmployabilityPage />} />
          <Route path="/pedagogy" element={<Pedagogy />} />
          <Route path="/activity-club" element={<ActivityClub />} />
          <Route path='/yuvamelodies' element={<YuvaMelodies/>} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path='/design' element={<Design/>} />
          <Route path='/thankyou' element={<Thankyou/>} />
          <Route path='*'  element={<PageNotFound/>}/>
        </Routes>
        <FooterSection/>
      </BrowserRouter>
      
      {/* <Nav />
      <HeaderSection />
      <Aboutyuva />
      <Count />
      <WhatWe />
      <Emplobility />
      <Footer /> */}
    </div>
  );
}

export default App;
