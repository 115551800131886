import React from 'react'
import { RiFacebookLine, RiInstagramLine } from "react-icons/ri";

export default function Rhythm() {
  return (
    <div className='pt-16'>
      <div className='container mx-auto rythms-background-image px-4'>
        <div className='center'>
          <h2 className="text-primary sm:text-h2 sm:text-center sub-heading text-h1 pb-3">YUVA <span className="text-secondary sm:text-h2 sm:text-center sub-heading text-h1 pb-3">RHYTHM</span> </h2>
        </div>
        <p className='text-text-color leading-[26px] tracking-wide pb-3 sm:text-sm'>
          The most emotionally and physically expressive performance art is Dance. What Dance is to different people varies? Some people perceive it as a passion, while others consider it entertainment. Chiefly, it is viewed as a means of self-expression and a way to transport oneself to a different, stress-free world of beats and rhythms. While opinions may differ, participating in this great exercise develops strength, flexibility, and knowledge of the body, posture, and balance.
        </p>
        <p className='text-text-color leading-[26px] tracking-wide pb-3 sm:text-sm'>
          One art of expressing thoughts is Dance. You will be able to perform complex songs with flair and graceful steps showing your ability to understand and match their performance to the Rhythm with the guidance of the RVS YUVA Rhythm team. The moves and the body language mended together will create a creative story. Further updates will be announced soon.
        </p>
        <div className='w-[100%]'>
          <div className='flex justify-end'>
            <a href="https://www.facebook.com/rvsyuvasulur.rhythm?mibextid=ZbWKwL">
            <div className='group cursor-pointer transition07 mx-3'>
              <div className='bg-[#d2d2d3] p-[2px] group-hover:bg-secondary'>
                <RiFacebookLine className='text-secondary text-h2 transition07 group-hover:text-white ' />
              </div>
            </div>
            </a>
            <a href="https://www.instagram.com/rvsyuvasulur.rhythm/">
            <div className='group cursor-pointer transition07'>
              <div className='bg-[#d2d2d3] p-[2px] group-hover:bg-secondary'>
                <RiInstagramLine className='text-secondary text-h2 transition07 group-hover:text-white ' />
              </div>
            </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
