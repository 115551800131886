import { React, useState, useEffect } from "react";
import 'animate.css';
import axios from "axios";
// import ScholarShipBanner from "../../Asset/Images/Scholarship/scholar-ship-banner.png"
import { states } from "./states";
import Srinivasan from "../../Asset/Images/About/Mentors/Srinivasan-Doraiswamy.webp"
// import ThankYou from '../../Asset/Images/thankYou.png'
import { useRef } from "react";
import Thank from "../../Asset/Images/thankpop.png";
import Fail from '../../Asset/Images/failpopup.png'

function ScholarshipBroucher() {
    // const [dist, setDist] = useState();
    // const [town,setTown]=useState()
    const [optiontown, setOptiontown] = useState()
    // const [open, setOpen] = useState(false)
    const [worng, setWrong] = useState(false)
    const [values, setValue] = useState({
        name: "",
        email: "",
        phonenumber: "",
        age: "",
        collegename: "",
        social: "",
        district: "",
        town: "",
        parentmobile: "",
        parentssocial: "",
    });
    const [errors, setErrors] = useState({});
    const [isSaveClick, setIsSaveClick] = useState(false);
    const reference = useRef()

    useEffect(() => {
        document.addEventListener("mousedown", (event) => {
            if (!reference.current.contains(event.target)) {
                // setOpen(false);
                setWrong(false)
            }
        })
    })


    const handleChange = (e) => {
        const { name, value } = e.target;
        setValue({
            ...values,
            [name]: value,
        });
        if (isSaveClick === true) {
            setErrors(errorHandler(values));
        }
    };

    const errorHandler = () => {
        var error = {};
        let regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
        let Number = /^[0-9]/;
        let Age = /^[0-9]/;
        if (values.name === "") {
            error.name = "Enter the Name";
        }
        if (values.email === "") {
            error.email = "Enter the Email";
        } else if (!regex.test(values.email)) {
            error.email = "Invalid email type";
        }
        if (values.phonenumber === "") {
            error.phonenumber = "Enter the phoneNumber";
        } else if (!Number.test(values.phonenumber)) {
            error.phonenumber = "must be a number";
        }
        if (values.age === "") {
            error.age = "Enter the age";
        } else if (!Age.test(values.age)) {
            error.age = "must be a number";
        }
        if (values.collegename === "") {
            error.collegename = "Enter the School or College Name";
        }
        if (values.district === "") {
            error.district = "Enter the Location - only panchayat and town panchayat areas";
        }
        if (values.town === "") {
            error.town = "Enter The Panchayat"
        }
        if (values.social === "") {
            error.social = "Enter the Social media Instagram account";
        }
        if (values.parentmobile === "") {
            error.parentmobile = "Enter the phoneNumber";
        } else if (!Number.test(values.parentmobile)) {
            error.parentmobile = "must be a number";
        }
        if (values.parentssocial === "") {
            error.parentssocial = "Enter the Parents Social media Instagram account";
        }
        return error;
    };

    // console.log(values);

    const handleOption = (e) => {
        // console.log( e.target.value);
        values.district = e.target.value
        values.town = ''

        if (isSaveClick === true) {
            setErrors(errorHandler(values))
        }
        setValue({ ...values, district: e.target.value })
        let townfilter = states.filter(v => v.districtname === e.target.value)
        if (isSaveClick === true) {
            setErrors(errorHandler(values))
        }
        setOptiontown(townfilter[0]["townname"])
    }

    const handletown = (e) => {
        values.town = e.target.value
        if (isSaveClick === true) {
            setErrors(errorHandler(values))
        }
        setValue({ ...values, town: e.target.value })
    }

    // console.log(dist);
    // setOpen(false)
    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSaveClick(true);
        var noerror = errorHandler(values);
        setErrors(noerror);

        if (Object.keys(noerror).length === 0) {
            var json = {
                name: values.name,
                email: values.email,
                phonenumber: values.phonenumber,
                age: values.age,
                collegename: values.collegename,
                social: values.social,
                district: values.district,
                town: values.town,
                parentmobile: values.parentmobile,
                parentssocial: values.parentssocial,
            };
            // console.log(json);
            // alert("sucess")

            axios
                // .post(`http://localhost:5000/api/yuvabrouchre`, json)
                .post(`https://rvscollege.com/api/yuvabrouchre`, json)
                .then((response) => {
                    setValue({
                        name: "",
                        email: "",
                        phonenumber: "",
                        age: "",
                        collegename: "",
                        social: "",
                        district: "",
                        town: "",
                        parentmobile: "",
                        parentssocial: "",
                    });

                    // SucessPopUp
                    // setOpen(true)
                    // setTimeout(() => {
                    //     setOpen(false)
                    // }, 5000);

                    const toDataURL = url => fetch(url)
                        .then(response => response.blob())
                        .then(blob => new Promise((resolve, reject) => {
                            const reader = new FileReader()
                            reader.onloadend = () => resolve(reader.result)
                            reader.onerror = reject
                            reader.readAsDataURL(blob)
                        }))

                    toDataURL(require('../../Asset/Scholarship.pdf'))
                        .then(dataUrl => {
                            // console.log('RESULT:', dataUrl)
                            const element = document.createElement('a');
                            element.setAttribute('download', 'RVS YUVA SCHOLARSHIP BROCHURE');
                            element.setAttribute('href', dataUrl);

                            element.style.display = 'none';

                            document.body.appendChild(element);

                            element.click();
                            document.body.removeChild(element);
                            setErrors({});

                            // console.log(response.data);
                            // alert(response.data)
                            // <Sucess/>
                            window.location = "/thankyou"
                        })

                })
                .catch((error) => {
                    setValue({
                        name: "",
                        email: "",
                        phonenumber: "",
                        age: "",
                        collegename: "",
                        social: "",
                        district: "",
                        town: "",
                        parentmobile: "",
                        parentssocial: "",
                    });
                    // console.log(error.response.data);
                    // alert("error")
                    setWrong(true)
                    setTimeout(() => {
                        setWrong(false)
                    }, 5000);
                });
        }
    };

    // open ? document.body.style.overflow = "hidden" : document.body.style.overflow = "auto";

    return (
        <div>
            {/* {open == true ?
                <div className="justify-center bg-modal backdrop-blur-sm items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none sm:w-[100%] sm:px-3">
                    <div className="thank rounded-lg scale-up-center" ref={reference}>
                        <img src={ThankYou} width="600" />
                        <div className="thankyou text-[42px] pb-8 text-center text-pureWhite">Thank You <br /> We Will Contact Soon</div>
                    </div>
                </div>
                : <div></div>
            } */}

            {/* {open === true ? <div className="justify-center bg-modal backdrop-blur-sm items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none sm:w-[100%] sm:px-3">
                <div className="thank rounded-lg scale-up-center" ref={reference}>
                    <img src={Thank} alt="Thank" width='600' />
                </div>
            </div> :
                <div></div>} */}

            {/* {worng == true ?
                <div className="justify-center bg-modal backdrop-blur-sm items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none sm:w-[100%] sm:px-3 ">
                    <div className="thank rounded-lg scale-up-center" ref={reference}>
                        <img />
                        <h2>Worng</h2>
                    </div>
                </div> :
                <></>
            } */}
            {worng === true ? <div className="justify-center bg-modal backdrop-blur-sm items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none sm:w-[100%] sm:px-3 ">
                <div className="thank rounded-lg scale-up-center" ref={reference}>
                    <img src={Fail} alt="fail" width='600' />
                </div>
            </div>
                : <div></div>}

            <div className="flex w-[100%] sm:p-4 p-10 center scholarship-background">
                <div className="w-[90%] sm:w-[98%]  p-6 mx-auto bg-white">
                <h1 className="text-h1 w-[100%] sm:text-h3 text-center py-2 text-secondary sub-heading">Educational <span className="text-h1 sm:text-h3 w-[100%] underline underline-offset-2 text-center py-2 text-primary sub-heading"> Scholarship</span> worth
Rs 1 Crore</h1>
                    <div className="w-[100%] flex mdsm:block lg:flex sm:block p-6 mdsm:p-2 lg:p-6 sm:p-0">
                        <div className="w-[65%] flex sm:w-[100%] mdsm:w-[100%] lg:w-[65%]">
                            <div className="w-[100%] sm:block flex mdsm:block lg:flex py-4 mdsm:py-0 lg:py-4 sm:py-0">
                                <div className="w-[30%] sm:w-[100%] mdsm:w-[100%] lg:w-[30%] ">
                                <img src={Srinivasan} alt="Srinivasan" className="ployback sm:w-[100%] sm:hidden visible" />

                                </div>
                                <div className="w-[70%]  sm:w-[100%] mdsm:w-[100%] lg:w-[70%] sm:px-2 mdsm:px-0 lg:px-10">
                                <p className="text-text-color leading-[26px] sm:text-sm pt-6 mdsm:pt-0 lg:pt-6">Are you a young aspiring musician seeking professional training to sharpen your skills? Here's a golden opportunity for you from <span className="font-bold">RVS YUVA!</span></p>
                                <p className="text-text-color leading-[26px] pt-6 sm:hidden">RVS YUVA has come up with this beautiful initiative by offering a music training program mentored by the versatile  singer<span className="font-bold"> Mr. Srinivas</span>. And the best part? You could avail of up to a 100% tuition fee waiver at RVS College of Arts and Science, Sulur, Coimbatore.</p>
                                <p className="text-text-color leading-[26px] pt-6 sm:hidden mdsm:pb-10 lg:pb-0">The program is open to individuals in the age group of 17-18 who have a passion for music and want to pursue it as a career.<span className="font-bold"> To qualify for the scholarship, all you need to do is to fill out the registration form and qualify for the auditions.</span></p>

                                </div>

                            </div>


                        </div>
                        <div className="w-[35%] center sm:w-[100%] mdsm:w-[100%] sm:pt-6 lg:w-[35%]  h-[100%]">

                        <form id="apply" className="w-[100%] ployback">
                                <div>
                                    <div className="borderPrimary">

                                   
                                    <div className="bg-primary  py-2 px-8">
                                        <p className="text-left text-white text-[24px] sm:text-[16px] title">
                                            Register Here
                                        </p>
                                    </div>
                                    </div>
                                    <div className="p-4 bg-white borderSecondaryLeft borders">
                                        <div className="w-[100%] pb-4 flex sm:flex-wrap">
                                            <div className="w-[50%] sm:w-[100%] sm:mb-3 mx-2">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={values.name}
                                                    onChange={(e) => handleChange(e)}
                                                    placeholder={`${errors.name ? "Enter Name" : "Name"
                                                        }`}
                                                    className={`${errors.name ? "bor1 placeholder:text-[#aa4029]" : ""
                                                        } borderColor px-4 focus:outline-none focus:border-[#555352] w-[100%] h-[40px] sm:h-[30px] md:h-[30px] mdsm:h-[30px] lg:h-[40px] sm:text-sm placeholder:text-text-color placeholder:text-sm`}
                                                />
                                            </div>
                                            <div className="w-[50%]  sm:w-[100%] sm:mb-0 sm:mx-2">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    value={values.email}
                                                    onChange={(e) => handleChange(e)}
                                                    placeholder={`${errors.email ? "Enter Email" : "Email"
                                                        }`}
                                                    className={`${errors.email ? "bor1 placeholder:text-[#aa4029] "
                                                        : ""
                                                        } borderColor px-4 focus:outline-none focus:border-[#555352] w-[100%] h-[40px] sm:h-[30px] md:h-[30px] mdsm:h-[30px] lg:h-[40px] placeholder:text-text-color sm:text-sm placeholder:text-sm`}
                                                />
                                            </div>

                                        </div>

                                        <div className="w-[100%] flex pb-4 sm:flex-wrap">
                                            <div className="w-[50%] sm:w-[100%] sm:mb-3 mx-2">
                                                <input
                                                    type="text"
                                                    name="phonenumber"
                                                    value={values.phonenumber}
                                                    placeholder={`${errors.phonenumber
                                                        ? "Mobile Number is required"
                                                        : "Phone Number"
                                                        }`}
                                                    className={`${errors.phonenumber
                                                        ? "bor1 placeholder:text-[#aa4029]"
                                                        : ""
                                                        } borderColor px-4 focus:outline-none focus:border-[#555352] w-[100%] h-[40px] sm:h-[30px] md:h-[30px] mdsm:h-[30px] lg:h-[40px] placeholder:text-text-color sm:text-sm placeholder:text-sm`}
                                                    onChange={(e) => handleChange(e)}
                                                    onKeyPress={(e) => {
                                                        if (!/^[0-9]/.test(e.key)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    maxLength={10}
                                                    minLength={10}
                                                />
                                            </div>
                                            <div className="w-[50%]  sm:w-[100%] sm:mb-0 sm:mx-2">
                                                <input
                                                    type="text"
                                                    name="age"
                                                    value={values.age}
                                                    onChange={(e) => handleChange(e)}
                                                    placeholder={`${errors.age
                                                        ? "Age is required"
                                                        : "Enter the Age"
                                                        }`}
                                                    className={`${errors.age ? "bor1 placeholder:text-[#aa4029]" : ""
                                                        } borderColor px-4 focus:outline-none focus:border-[#555352] w-[100%] h-[40px] sm:h-[30px] md:h-[30px] mdsm:h-[30px] lg:h-[40px] placeholder:text-text-color sm:text-sm placeholder:text-sm`}
                                                    onKeyPress={(e) => {
                                                        if (!/^[0-9]/.test(e.key)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    maxLength={2}
                                                    minLength={2}
                                                />
                                            </div>

                                        </div>

                                        <div className="w-[100%] flex pb-4 sm:flex-wrap">
                                            <div className="w-[50%] sm:w-[100%] sm:mb-3 mx-2">
                                                <input
                                                    type="text"
                                                    name="collegename"
                                                    value={values.collegename}
                                                    onChange={(e) => handleChange(e)}
                                                    placeholder={`${errors.collegename
                                                        ? "College name is required"
                                                        : "School or College Name"
                                                        }`}
                                                    className={`${errors.collegename
                                                        ? "bor1 placeholder:text-[#aa4029]"
                                                        : ""
                                                        } borderColor px-4 focus:outline-none focus:border-[#555352] w-[100%] h-[40px] sm:h-[30px] md:h-[30px] mdsm:h-[30px] lg:h-[40px] sm:text-sm placeholder:text-text-color placeholder:text-sm`}
                                                />
                                            </div>
                                            <div className="w-[50%] sm:w-[100%] sm:mb-0 sm:mx-2">
                                                <input
                                                    type="text"
                                                    name="social"
                                                    value={values.social}
                                                    onChange={(e) => handleChange(e)}
                                                    placeholder={`${errors.social
                                                        ? "Social Media is Required"
                                                        : "Social media Instagram account"
                                                        } `}
                                                    className={`${errors.social
                                                        ? "bor1 placeholder:text-[#aa4029]"
                                                        : ""
                                                        } borderColor px-4 focus:outline-none focus:border-[#555352] w-[100%] h-[40px] sm:h-[30px] md:h-[30px] mdsm:h-[30px] lg:h-[40px] sm:text-sm placeholder:text-text-color placeholder:text-sm`}
                                                />
                                            </div>

                                        </div>

                                        <div className="w-[100%] flex pb-2 sm:flex-wrap">

                                            <div className="w-[50%] sm:w-[100%] sm:mb-3 mx-2">
                                                <select onChange={(e) => handleOption(e)} name="district" value={values.district}
                                                    className={`${errors.district
                                                        ? "bor1 placeholder:text-[#aa4029]"
                                                        : ""
                                                        } borderColor px-4 focus:outline-none text-text-color text-tiny focus:border-[#555352] w-[100%] h-[40px] sm:h-[30px] md:h-[30px] mdsm:h-[30px] lg:h-[40px] sm:text-sm placeholder:text-text-color placeholder:text-sm`}>
                                                    <option style={{ display: "none" }} className="placeholder:text-sm text-tiny">--Select District--</option>
                                                    {states?.map((v, i) => <><option value={v.districtname} className="placeholder:text-sm text-tiny" >{v.districtname}</option></>)}
                                                </select>
                                            </div>


                                            <div className="w-[50%] sm:w-[100%] sm:mb-0 sm:mx-2">
                                                <select name="town" onChange={(e) => handletown(e)} value={values.town}
                                                    className={`${errors.town
                                                        ? "bor1 placeholder:text-[#aa4029]"
                                                        : ""
                                                        } borderColor px-4 focus:outline-none text-text-color text-tiny focus:border-[#555352] w-[100%] h-[40px] sm:h-[30px] md:h-[30px] mdsm:h-[30px] lg:h-[40px] sm:text-sm placeholder:text-text-color placeholder:text-sm`}
                                                >
                                                    <option className="placeholder:text-sm text-tiny" style={{ display: "none" }}>--Select Town--</option>
                                                    {optiontown?.map((v) => <><option className="placeholder:text-sm text-tiny" value={v.townname}>{v}</option></>)}
                                                </select>
                                            </div>

                                        </div>

                                        <h2 className="px-2 pt-2 pb-2 text-h6 text-text-color">Parents Details</h2>

                                        <div className="w-[100%] flex pb-4 sm:flex-wrap">

                                            <div className="w-[50%] sm:w-[100%] sm:mb-3 mx-2">
                                                <input
                                                    type="text"
                                                    name="parentssocial"
                                                    value={values.parentssocial}
                                                    onChange={(e) => handleChange(e)}
                                                    placeholder={`${errors.parentssocial ? "Parents Name Required" : "Parents Name"}`}
                                                    className={`${errors.parentssocial ? "bor1 placeholder:text-[#aa4029]" : ""} borderColor px-4 focus:outline-none focus:border-[#555352] w-[100%] h-[40px] sm:h-[30px] md:h-[30px] mdsm:h-[30px] lg:h-[40px] sm:text-sm placeholder:text-text-color placeholder:text-sm`}
                                                />
                                            </div>

                                            <div className="w-[50%] sm:w-[100%] sm:mb-0 sm:mx-2">
                                                <input
                                                    type="text"
                                                    name="parentmobile"
                                                    value={values.parentmobile}
                                                    onChange={(e) => handleChange(e)}
                                                    placeholder={`${errors.parentmobile
                                                        ? "Parents Mobile number Required"
                                                        : "Parents Mobile number "
                                                        }  `}
                                                    className={`borderColor ${errors.parentmobile
                                                        ? "bor1 placeholder:text-[#aa4029]"
                                                        : ""
                                                        } px-4 focus:outline-none focus:border-[#555352] w-[100%] h-[40px] sm:h-[30px] md:h-[30px] mdsm:h-[30px] lg:h-[40px] sm:text-sm placeholder:text-text-color placeholder:text-sm`}
                                                    onKeyPress={(e) => {
                                                        if (!/^[0-9]/.test(e.key)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    maxLength={10}
                                                    minLength={10}
                                                />
                                            </div>

                                        </div>

                                        <div className="text-center pb-2">
                                            <button
                                                onClick={(e) => handleSubmit(e)}
                                                // onClick={(e) => window.location.reload()}
                                                className="bg-primary hover:bg-secondary transition07 text-white py-2 mt-1  px-4 w-[60%] text-sm sm:text-sm"
                                            >
                                                Submit{" "}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                    <div className="bg-[#f2f2f2] py-10 px-4 my-4 sm:px-2">
                    <p className="text-text-color pl-4 border-l-8 border-primary sm:text-sm  leading-[26px] ">An incredible opportunity to learn from a renowned singer and gain valuable skills and knowledge to excel in your musical journey! Take this chance to uplift your music skills to the next level. For more details, write to <span className="text-secondary font-bold transition07 hover:text-primary" > <a href="mailto:scholarships@rvsyuva.org">scholarships@rvsyuva.org</a></span> T&C <a href="#apply"><span className="text-secondary font-bold transition07 hover:text-primary">apply</span></a>

</p>
                    </div>
              


                </div>



            </div>
            {/* <div className=" scholarship-background">
                <div className="container mx-auto">
                    <div className="w-[100%] flex sm:block mdsm:block lg:flex">
                        <div className="w-[60%] sm:w-[100%] mdsm:w-[100%] lg:w-[60%] ">
                            <div className="p-8 sm:p-4">
                                <div className="text-white sub-heading text-h1 sm:text-h2 sm:pt-4">Educational Scholarship worth <br /> Rs 1 Crore</div>
                                <div className="w-[100%]  sm:block flex py-6">
                                    <div className="w-[40%] sm:w-[100%]">
                                        <div className="relative sm:hidden">
                                            <img src={Srinivasan} alt="Srinivasan" height="100%" width="100%" className="ployback sm:w-[100%]" />
                                            <div className="absolute bottom-0 w-[90%]">
                                                <div className="bg-  text-center kalam-font text-h3 text-white w-[100%] py-2 px-6">
                                                    Mr. Srinivas
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-[60%] px-10 sm:px-1 sm:w-[100%] ">
                                        <p className="text-h1 text-white pt-16 sub-heading sm:text-h3 ">Srinivasan Doraiswamy</p>
                                        <p className="text-white pt-4 leading-[26px] text-justify sm:text-sm ">Are you a young aspiring musician seeking professional training to sharpen your skills? Here's a golden opportunity for you from <span className="font-bold ">RVS YUVA!</span></p>
                                        <p className="text-white sm:hidden pt-4 leading-[26px] text-justify sm:text-sm">RVS YUVA has come up with this beautiful initiative by offering a music training program mentored by the versatile singer Mr. Srinivas. And the best part? You could avail of up to a 100% tuition fee waiver at RVS College of Arts and Science, Sulur, Coimbatore.</p>
                                    </div>
                                </div>
                                <p className="text-white pt-4 sm:hidden leading-[26px] text-justify sm:text-sm">The program is open to individuals in the age group of 17-18 who have a passion for music and want to pursue it as a career. To qualify for the scholarship, all you need to do is to fill out the registration form and qualify for the auditions.</p>
                                <p className="text-white pt-4 sm:hidden leading-[26px] text-justify sm:text-sm">An incredible opportunity to learn from a renowned singer and gain valuable skills and knowledge to excel in your musical journey! Take this chance to uplift your music skills to the next level. For more details, write to <a href="mailto:scholarships@rvsyuva.org" className="hover:text-black">scholarships@rvsyuva.org</a> T&C apply</p>
                            </div>
                        </div>
                        <div className="w-[40%] sm:w-[100%] center mdsm:w-[100%] lg:w-[40%]">
                            <form className="w-[100%] ployback mdsm:p-8 sm:p-6 mb-[250px]">
                                <div>
                                    <div className="bg-primary py-2 px-8">
                                        <p className="text-left text-white text-[24px] sm:text-[16px] title">
                                            Register Here
                                        </p>
                                    </div>
                                    <div className="p-4 bg-white borders">
                                        <div className="w-[100%] pb-4 flex sm:flex-wrap">
                                            <div className="w-[50%] sm:w-[100%] sm:mb-3 mx-2">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={values.name}
                                                    onChange={(e) => handleChange(e)}
                                                    placeholder={`${errors.name ? "Enter Name" : "Name"
                                                        }`}
                                                    className={`${errors.name ? "bor1 placeholder:text-[#aa4029]" : ""
                                                        } borderColor px-4 focus:outline-none focus:border-[#555352] w-[100%] h-[40px] sm:h-[30px] md:h-[30px] mdsm:h-[30px] lg:h-[40px] sm:text-sm placeholder:text-text-color placeholder:text-sm`}
                                                />
                                            </div>
                                            <div className="w-[50%]  sm:w-[100%] sm:mb-0 sm:mx-2">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    value={values.email}
                                                    onChange={(e) => handleChange(e)}
                                                    placeholder={`${errors.email ? "Enter Email" : "Email"
                                                        }`}
                                                    className={`${errors.email ? "bor1 placeholder:text-[#aa4029] "
                                                        : ""
                                                        } borderColor px-4 focus:outline-none focus:border-[#555352] w-[100%] h-[40px] sm:h-[30px] md:h-[30px] mdsm:h-[30px] lg:h-[40px] placeholder:text-text-color sm:text-sm`}
                                                />
                                            </div>

                                        </div>

                                        <div className="w-[100%] flex pb-4 sm:flex-wrap">
                                            <div className="w-[50%] sm:w-[100%] sm:mb-3 mx-2">
                                                <input
                                                    type="text"
                                                    name="phonenumber"
                                                    value={values.phonenumber}
                                                    placeholder={`${errors.phonenumber
                                                        ? "Mobile Number is required"
                                                        : "Phone Number"
                                                        }`}
                                                    className={`${errors.phonenumber
                                                        ? "bor1 placeholder:text-[#aa4029]"
                                                        : ""
                                                        } borderColor px-4 focus:outline-none focus:border-[#555352] w-[100%] h-[40px] sm:h-[30px] md:h-[30px] mdsm:h-[30px] lg:h-[40px] placeholder:text-text-color sm:text-sm`}
                                                    onChange={(e) => handleChange(e)}
                                                    onKeyPress={(e) => {
                                                        if (!/^[0-9]/.test(e.key)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    maxLength={10}
                                                    minLength={10}
                                                />
                                            </div>
                                            <div className="w-[50%]  sm:w-[100%] sm:mb-0 sm:mx-2">
                                                <input
                                                    type="text"
                                                    name="age"
                                                    value={values.age}
                                                    onChange={(e) => handleChange(e)}
                                                    placeholder={`${errors.age
                                                        ? "Age is required"
                                                        : "Enter the Age"
                                                        }`}
                                                    className={`${errors.age ? "bor1 placeholder:text-[#aa4029]" : ""
                                                        } borderColor px-4 focus:outline-none focus:border-[#555352] w-[100%] h-[40px] sm:h-[30px] md:h-[30px] mdsm:h-[30px] lg:h-[40px] placeholder:text-text-color sm:text-sm`}
                                                    onKeyPress={(e) => {
                                                        if (!/^[0-9]/.test(e.key)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    maxLength={2}
                                                    minLength={2}
                                                />
                                            </div>

                                        </div>

                                        <div className="w-[100%] flex pb-4 sm:flex-wrap">
                                            <div className="w-[50%] sm:w-[100%] sm:mb-3 mx-2">
                                                <input
                                                    type="text"
                                                    name="collegename"
                                                    value={values.collegename}
                                                    onChange={(e) => handleChange(e)}
                                                    placeholder={`${errors.collegename
                                                        ? "College name is required"
                                                        : "School or College Name"
                                                        }`}
                                                    className={`${errors.collegename
                                                        ? "bor1 placeholder:text-[#aa4029]"
                                                        : ""
                                                        } borderColor px-4 focus:outline-none focus:border-[#555352] w-[100%] h-[40px] sm:h-[30px] md:h-[30px] mdsm:h-[30px] lg:h-[40px] sm:text-sm placeholder:text-text-color`}
                                                />
                                            </div>
                                            <div className="w-[50%] sm:w-[100%] sm:mb-0 sm:mx-2">
                                                <input
                                                    type="text"
                                                    name="social"
                                                    value={values.social}
                                                    onChange={(e) => handleChange(e)}
                                                    placeholder={`${errors.social
                                                        ? "Social Media is Required"
                                                        : "Social media Instagram account"
                                                        } `}
                                                    className={`${errors.social
                                                        ? "bor1 placeholder:text-[#aa4029]"
                                                        : ""
                                                        } borderColor px-4 focus:outline-none focus:border-[#555352] w-[100%] h-[40px] sm:h-[30px] md:h-[30px] mdsm:h-[30px] lg:h-[40px] sm:text-sm placeholder:text-text-color`}
                                                />
                                            </div>

                                        </div>

                                        <div className="w-[100%] flex pb-4 sm:flex-wrap">

                                            <div className="w-[50%] sm:w-[100%] sm:mb-3 mx-2">
                                                <select onChange={(e) => handleOption(e)} name="district" value={values.district}
                                                    className={`${errors.district
                                                        ? "bor1 placeholder:text-[#aa4029]"
                                                        : ""
                                                        } borderColor px-4 focus:outline-none text-text-color focus:border-[#555352] w-[100%] h-[40px] sm:h-[30px] md:h-[30px] mdsm:h-[30px] lg:h-[40px] sm:text-sm placeholder:text-text-color`}>
                                                    <option style={{ display: "none" }}>--Select District--</option>
                                                    {states?.map((v, i) => <><option value={v.districtname} >{v.districtname}</option></>)}
                                                </select>
                                            </div>


                                            <div className="w-[50%] sm:w-[100%] sm:mb-0 sm:mx-2">
                                                <select name="town" onChange={(e) => handletown(e)} value={values.town}
                                                    className={`${errors.town
                                                        ? "bor1 placeholder:text-[#aa4029]"
                                                        : ""
                                                        } borderColor px-4 focus:outline-none text-text-color focus:border-[#555352] w-[100%] h-[40px] sm:h-[30px] md:h-[30px] mdsm:h-[30px] lg:h-[40px] sm:text-sm placeholder:text-text-color`}
                                                >
                                                    <option style={{ display: "none" }}>--Select Town--</option>
                                                    {optiontown?.map((v) => <><option value={v.townname}>{v}</option></>)}
                                                </select>
                                            </div>

                                        </div>

                                        <h2 className="px-2 pt-4 pb-2 text-h4 text-text-color">Parents Details</h2>

                                        <div className="w-[100%] flex pb-4 sm:flex-wrap">

                                            <div className="w-[50%] sm:w-[100%] sm:mb-3 mx-2">
                                                <input
                                                    type="text"
                                                    name="parentssocial"
                                                    value={values.parentssocial}
                                                    onChange={(e) => handleChange(e)}
                                                    placeholder={`${errors.parentssocial ? "Parents Name Required" : "Parents Name"}`}
                                                    className={`${errors.parentssocial ? "bor1 placeholder:text-[#aa4029]" : ""} borderColor px-4 focus:outline-none focus:border-[#555352] w-[100%] h-[40px] sm:h-[30px] md:h-[30px] mdsm:h-[30px] lg:h-[40px] sm:text-sm placeholder:text-text-color`}
                                                />
                                            </div>

                                            <div className="w-[50%] sm:w-[100%] sm:mb-0 sm:mx-2">
                                                <input
                                                    type="text"
                                                    name="parentmobile"
                                                    value={values.parentmobile}
                                                    onChange={(e) => handleChange(e)}
                                                    placeholder={`${errors.parentmobile
                                                        ? "Parents Mobile number Required"
                                                        : "Parents Mobile number "
                                                        }  `}
                                                    className={`borderColor ${errors.parentmobile
                                                        ? "bor1 placeholder:text-[#aa4029]"
                                                        : ""
                                                        } px-4 focus:outline-none focus:border-[#555352] w-[100%] h-[40px] sm:h-[30px] md:h-[30px] mdsm:h-[30px] lg:h-[40px] sm:text-sm placeholder:text-text-color`}
                                                    onKeyPress={(e) => {
                                                        if (!/^[0-9]/.test(e.key)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    maxLength={10}
                                                    minLength={10}
                                                />
                                            </div>

                                        </div>

                                        <div className="text-center pb-2">
                                            <button
                                                onClick={(e) => handleSubmit(e)}
                                                // onClick={(e) => window.location.reload()}
                                                className="bg-primary hover:bg-secondary transition07 text-white py-2 mt-1  px-4 w-[60%] sm:text-sm"
                                            >
                                                Submit{" "}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <p className="text-white leading-[26px] text-justify sm:text-sm">An incredible opportunity to learn from a renowned singer and gain valuable skills and knowledge to excel in your musical journey! Take this chance to uplift your music skills to the next level. For more details, write to scholarships@rvsyuva.org T&C apply</p>
                    </div>
                </div>

            </div> */}
        </div>
    )
}
export default ScholarshipBroucher