import React from 'react'
import $ from 'jquery'

export default function YuvaMelodiesss() {

    $(document).ready(function () {
        $(window).scroll(function () {
            if ($(document).scrollTop() > 0) {
                $("#scroll").addClass("test");
            } else {
                $("#scroll").removeClass("test");
            }
        });
    });

    $(document).ready(function () {
        $(window).scroll(function () {
            if ($(document).scrollTop() > 0) {
                $("#scroll1").addClass("test");
            } else {
                $("#scroll1").removeClass("test");
            }
        });
    });

    $(document).ready(function () {
        $(window).scroll(function () {
            if ($(document).scrollTop() > 0) {
                $("#scroll2").addClass("test");
            } else {
                $("#scroll2").removeClass("test");
            }
        });
    });

    $(document).ready(function () {
        $(window).scroll(function () {
            if ($(document).scrollTop() > 0) {
                $("#scroll3").addClass("test");
            } else {
                $("#scroll3").removeClass("test");
            }
        });
    });

    $(document).ready(function () {
        $(window).scroll(function () {
            if ($(document).scrollTop() > 0) {
                $("#scroll4").addClass("test");
            } else {
                $("#scroll4").removeClass("test");
            }
        });
    });

    $(document).ready(function () {
        $(window).scroll(function () {
            if ($(document).scrollTop() > 0) {
                $("#scroll5").addClass("test");
            } else {
                $("#scroll5").removeClass("test");
            }
        });
    });


    return (
        <div>
            {/* YUVA MELODIES */}
            <section>
                <div className='flex flex-row'>

                    <div className='px-[50px] pt-[90px] sm:px-[5px]'>
                        <div className='sticky top-5 sm:sticky sm:top-5'>
                            <p className='w-[250px] sm:w-[150px] sm:text-sm text-secondary sub-heading text-h4'>Program: <span className="text-grey sub-heading">Music training</span></p>
                            <p className='w-[250px] sm:text-sm text-secondary sub-heading text-h4'>Genre: <span className="text-grey sub-heading">All</span></p>
                        </div>
                    </div>

                    <div class="mt-10 border border-border-color border-r-border-color"></div>

                    <div className='p-[100px]'>
                        <h2 id='scroll' className="text-primary sub-heading text-left text-h3 pb-3 sticky top-0">YUVA <span className="text-secondary sub-heading text-h3">MELODIES</span> </h2>
                        <p className='text-text-color leading-[26px] tracking-wide pb-3 text-justify'>RVS YUVA is for YOU. It is enormous. We provide students who are interested in music with expert instruction. RVS YUVA SULUR reached out to Mr. Srinivas, a playback singer, a Music composer who has sung more than 2000 songs in Tamil, Malayalam, Kannada, and Telugu keeping in front of him the noble cause and our humble initiative seeking his valuable contribution towards this initiative.</p>
                        <p className='text-text-color leading-[26px] tracking-wide pb-3 text-justify'>As a result, he accepted this humble request, visited our campus, met the students, and shared his experience. Further, he met the first batch of students, reviewed their singing and instrumental performances, and gave valuable suggestions. He suggested two dynamic and expert singers for training students, Sreeraj Sahajan and Lakshmi Ennappadam.</p>
                        <p className='text-text-color leading-[26px] tracking-wide pb-3 text-justify'>Music is being taught to more than 50 students in and around Coimbatore. When a legendary singer and his expert team come to our campus to review and train your singing and music talent,It is a once-in-a-lifetime chance for you to immerse yourself in music and people's hearts and souls through their voices and instruments. If you are one of these musicians, we are eager to welcome you and accompany you on your musical journey. Your musical career path is laid out for you at RVS YUVA SULUR, and you will receive the necessary support.</p>
                        <p className='text-text-color leading-[26px] tracking-wide pb-3 text-justify'>
                            Srinivasan Doraiswamy, also known as Srinivas, is an Indian playback singer who has performed over 2000 songs in Tamil, Malayalam, Telugu, Kannada, and Hindi. He has also worked as an independent music composer on films and private records. He has been a judge for various reality singing shows. He has won many awards and gained recognition. His song "Minsara Poove" from the film "Padayappa" earned him the Tamil Nadu Government's Award for Best Playback Singer. He was also named best playback vocalist at the International Tamil Film Awards hosted in Malaysia for the song "Apple Penne" from Roja Kootam. In 2006, the Tamil Nadu government bestowed the Kalaimamani title on him and the Kerala State Award for the song "Bansuri" from the film "Rathri Mazha."
                        </p>
                        <p className='text-text-color leading-[26px] tracking-wide pb-3 text-justify'>
                            Sreeraj Sahajan is a Hindustani classical singer and also a trainer. He began his career as a stage performer. After that, he became a playback singer in the Indian film industry. Step by step, he enhanced his musical skill. Now he is a music Director and Composer. he has been a student of Hindustani classical music under Vijay Sursen for six years. He does Vocal tuning with high precision, music arranging, programming, and mixing in various genres. He does Voice-overs with perfection and is good at audio editing.
                        </p>
                        <p className='text-text-color leading-[26px] tracking-wide pb-3 text-justify'>
                            Lakshmi Ennappaadam is a certified professional vocal trainer and lyricist. She is a dual M.A. (master) degree holder in Carnatic music and Hindustani Classical music and is another mentor. She mastered Hindustani and Carnatic music from Akhil Bharatiya Gandharva Mahavidyalaya, Mumbai. She is currently working as a music teacher at Palarivattom Trinetra Arts Academy. She is the founder, director, and CEO at ALMS AL Music school in Kerala.
                        </p>
                        <p className='text-text-color leading-[26px] tracking-wide pb-3 text-justify'>
                            To learn more about YUVA Melodies, visit our Instagram page. We use this page to reach out to more contests, and the winners will receive prizes. To get the word out, we also recorded songs and posted them as videos. An audience is also needed for an artist. Future students have an excellent opportunity to shine. We cordially invite all boys and girls to gain valuable experience with us.
                        </p>
                    </div>
                </div>
            </section>

            {/* YUVA CAPTURE [PHOTOGRAPHY] */}
            <section>
                <div className='flex flex-row'>

                    <div className='px-[50px] pt-[90px]'>
                        <div className='sticky top-5'>
                            <p className='w-[250px] text-secondary sub-heading text-h4'>Program: <span className="text-grey sub-heading">Training on photography</span></p>
                            <p className='w-[250px] text-secondary sub-heading text-h4'>Genre: <span className="text-grey sub-heading">General</span></p>
                        </div>
                    </div>

                    <div class="mt-10 border border-border-color border-r-border-color"></div>

                    <div className='p-[100px]'>
                        <h2 id='scroll1' className="text-primary sub-heading text-left text-h3 pb-3 sticky top-0">YUVA <span className="text-secondary sub-heading text-h3">CAPTURE (PHOTOGRAPHY)</span> </h2>
                        {/* <h2 id='scroll' className="text-primary sub-heading text-left text-h3 pb-3 sticky top-0">YUVA <span className="text-secondary sub-heading text-h3">MELODIES</span> </h2> */}
                        <p className='text-text-color leading-[26px] tracking-wide pb-3 text-justify'>Let's explore vibrant photography ideas with  RVS YUVA Capture. We invite Mr. Sudharak Olwe, the crown jewel of photography, to mold our students' photographic skills. Mr. Sudharak Olwe, who won the Padma Shri award from the President of India, the fourth highest civilian award, will visit RVS exclusively for the YUVA Capture club students. Sudharak's photography is compassionate. It is a journey into the unseen view of the current condition of humanity. His photography crosses all boundaries and tells an emotional tale of individuals and society. He received one of National Geographic's "All Roads Photography Program Four'' 's honors in 2005.</p>
                        <p className='text-text-color leading-[26px] tracking-wide pb-3 text-justify'>Sudharak Olwe has been a Mumbai-based photojournalist since 1988 and has worked as a press photographer with some of the leading newspapers in India. Sudharak has travelled the length and breadth of the country, witnessing incredible stories of resilience, courage, and change. He has partnered with numerous non-governmental organisations to present their outstanding work with both rural and urban communities.</p>
                        <p className='text-text-color leading-[26px] tracking-wide pb-3 text-justify'>He founded The Photography Promotion Trust (PPT)  in 2005, the trust has constantly strived to inspire young individuals to share and learn about social issues via photography.</p>
                        <p className='text-text-color leading-[26px] tracking-wide pb-3 text-justify'>
                            Mr. Sudharak Olwe, a winner of the Padma Shri award, will assist RVS YUVA CAPTURE. A photography contest will be conducted and 220 students will be selected out of which 20 students will be given personal training by Olwe sir himself. The 200 students will receive online sessions on photography, techniques, and hacks that will come in handy when representing things that were not created or spoken about, or when showing the dark side or other perspectives on things. We might also learn about social photography and other techniques that will enable students to explore and express their thoughts through clicks.
                        </p>
                        <p className='text-text-color leading-[26px] tracking-wide pb-3 text-justify'>
                            It's the right moment to start learning, to capture the glimmer of hope, and to join Sudharak Olwe in exploring the breadth and nature of photography. Prepare your camera because he will be training the RVS YUVA Capture students. We are also looking forward to welcoming him.
                        </p>

                    </div>
                </div>
            </section>

            {/* RVS YUVA SPORTS */}
            <section>
                <div className='flex flex-row'>

                    <div className='px-[50px] pt-[90px]'>
                        <div className='sticky top-5'>
                            <p className='w-[250px] text-secondary sub-heading text-h4'>Program: <span className="text-grey sub-heading">Sports Training</span></p>
                            <p className='w-[250px] text-secondary sub-heading text-h4'>Duration : <span className="text-grey sub-heading">To be updated soon</span></p>
                        </div>
                    </div>

                    <div class="mt-10 border border-border-color border-r-border-color"></div>

                    <div className='p-[100px]'>
                        <h2 id='scroll2' className="text-primary sub-heading text-left text-h3 pb-3 sticky top-0">RVS <span className="text-secondary sub-heading text-h3">YUVA SPORTS</span> </h2>
                        <p className='text-text-color leading-[26px] tracking-wide pb-3 text-justify'>RVS YUVA SPORTS caters to young athletes. It directs you down the correct path in your chosen sport. You could use this as a platform to hone your athletic abilities while also learning key life lessons.</p>
                        <p className='text-text-color leading-[26px] tracking-wide pb-3 text-justify'>Football is one of the most popular sports in the world, and it has a huge fan base. The RVS YUVA SPORTS club provides suitable outdoor and indoor sports training. Its players compete in field tournaments at various levels, including zonal, district, state, and national.</p>
                        <p className='text-text-color leading-[26px] tracking-wide pb-3 text-justify'>As a result, an exclusive training will be established to train young football players with the utmost dedication in order to provide standard training for you to achieve various level matches ranging from regional to international level. A non-profit organisation called India Khelo Football is boosting India's grassroots ecology. It is creating a platform that connects prospective football players with opportunities in an open and democratic manner. They want to establish a robust key talent for Indian football through this platform. We intend to collaborate with them in order to provide opportunities for our deserving players to compete in international matches. Students who want to run towards their football goal are warmly welcomed to join this club.</p>

                    </div>
                </div>
            </section>

            {/* RVS YUVA SHORT FILM */}
            <section>
                <div className='flex flex-row'>

                    <div className='px-[50px] pt-[90px]'>
                        <div className='sticky top-5'>
                            <p className='w-[250px] text-secondary sub-heading text-h4'>Program: <span className="text-grey sub-heading">Short film making</span></p>
                            <p className='w-[250px] text-secondary sub-heading text-h4'>Duration: <span className="text-grey sub-heading">To be updated soon</span></p>
                        </div>
                    </div>

                    <div class="mt-10 border border-border-color border-r-border-color"></div>

                    <div className='p-[100px]'>
                        <h2 id='scroll3' className="text-primary sub-heading text-left text-h3 pb-3 sticky top-0">RVS YUVA  <span className="text-secondary sub-heading text-h3">SHORT FILM</span> </h2>
                        <p className='text-text-color leading-[26px] tracking-wide pb-3 text-justify'>Now is the time for film audiences around the globe to recognise short films. Short films are far less costly than feature films and are not anticipated to make a great deal of revenue.It means that directors are significantly more likely to fund films that are bold and experimental. It encourages filmmakers to experiment much more and create a specific style. So we will give opportunities to the students who love to write, do makeup, choreography, voice-over artist, Editor, Cameraman, etc. We are ready to train you.</p>
                        <p className='text-text-color leading-[26px] tracking-wide pb-3 text-justify'>Short film club took up training sessions to share ideas about acting, directing, and screenwriting. It entails tasks such as recreating advertisements and expressing the same dialogue in multiple emotions. Classes are in session. Be a professional as soon as possible. We have a lot of ideas. We'll talk about it in the YUVA Short Film Club.</p>
                        <p className='text-text-color leading-[26px] tracking-wide pb-3 text-justify'></p>
                    </div>
                </div>
            </section>

            {/* RVS YUVA RHYTHM */}
            <section>
                <div className='flex flex-row'>

                    <div className='px-[50px] pt-[90px]'>
                        <div className='sticky top-5'>
                            <p className='w-[250px] text-secondary sub-heading text-h4'>Program: <span className="text-grey sub-heading">Dance Training</span></p>
                            <p className='w-[250px] text-secondary sub-heading text-h4'>Duration: <span className="text-grey sub-heading">To be updated soon</span></p>
                            <p className='w-[250px] text-secondary sub-heading text-h4'>Genre : <span className="text-grey sub-heading">All</span></p>
                        </div>
                    </div>

                    <div class="mt-10 border border-border-color border-r-border-color"></div>

                    <div className='p-[100px]'>
                        <h2 id='scroll4' className="text-primary sub-heading text-left text-h3 pb-3 sticky top-0">RVS YUVA<span className="text-secondary sub-heading text-h3"> RHYTHM</span> </h2>
                        <p className='text-text-color leading-[26px] tracking-wide pb-3 text-justify'>
                            RVS YUVA RHYTHM is the ideal setting to show off your upbeat dance moves, and it was developed as a place to discover new styles and master them. This group encourages you to participate in various dance activities, including flash mobs, cultural events, and intercollegiate competitions. You will undergo training from choreographers and performers in various well-known dance styles, including Bharatanatyam, hip-hop, freestyle, fusion, and folk dance. You'd have an incredible opportunity to interact with those experts and learn what life is like for dancers. Through these experiences, you will learn how to confront and conquer stage fright. You can join and explore your dancing abilities in this RVS YUVA RHYTHM, gain insight into other cultures by learning a new dance genre, and undoubtedly increase your self-confidence and self-esteem by perfecting your techniques. Nevertheless, dancing may open up new job prospects, such as those as a choreographer in the film industry or as a dance instructor.
                        </p>
                        <p className='text-text-color leading-[26px] tracking-wide pb-3 text-justify'></p>
                    </div>
                </div>
            </section>

            {/* RVS YUVA ARTISTRY */}
            <section>
                <div className='flex flex-row'>

                    <div className='px-[50px] pt-[90px]'>
                        <div className='sticky top-5'>
                            <p className='w-[250px] text-secondary sub-heading text-h4'>Program: <span className="text-grey sub-heading">Training on art and craft</span></p>
                            <p className='w-[250px] text-secondary sub-heading text-h4'>Duration: <span className="text-grey sub-heading">yet to be announced</span></p>

                        </div>
                    </div>

                    <div class="mt-10 border border-border-color border-r-border-color"></div>

                    <div className='p-[100px]'>
                        <h2 id='scroll5' className="text-primary sub-heading text-left text-h3 pb-3 sticky top-0">RVS YUVA<span className="text-secondary sub-heading text-h3"> ARTISTRY</span> </h2>
                        <p className='text-text-color leading-[26px] tracking-wide pb-3 text-justify'>
                            In RVS YUVA ARTISTRY, you can learn more about creating artwork. Although it can be challenging to see, the individual who does it enjoys doing art and craft. Students can join this group if they desire to be creators. Every artist was first an admirer. Therefore, you already have admirers, and now it's time to create art and show the world your talents, as we have made it possible for creators to share their ideas. Every artist dreams of creating a masterpiece. You can find your masterpiece and take appropriate steps from YUVA ARTISTRY to create it. It takes a lot of practice and creativity to draw and color. Therefore, it's a complex task. Getting the best painting takes a lot of time. YUVA ARTISTRY is designed to equip students who are seriously interested in it rather than professionals. You can discover your best artwork with the aid of our club. They are crafting and creating things that need magical hands and a sharp mind. Some unique ideas are required to turn trash into beautiful artwork.
                        </p>
                        <p className='text-text-color leading-[26px] tracking-wide pb-3 text-justify'></p>
                    </div>
                </div>
            </section>
        </div>
    )
}
