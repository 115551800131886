import { useState } from "react";
import Logo from "../Asset/Images/Home/yuva.webp"
// import { RiInstagramLine, RiTwitterLine, RiWhatsappLine, RiFacebookLine } from "react-icons/ri";
import $ from "jquery";
import { NavLink } from 'react-router-dom'
import { HiViewList, } from "react-icons/hi";
import { HiXMark } from "react-icons/hi2";
function Navbar() {
    const [navbar, setNavbar] = useState(false)
    const eventHandler = (e) => {
        $(".mobile-view").toggle();
        setNavbar(!navbar)
    }
    return (
        <div>
            {/* Mobile View */}
            <div className="mobile-nav">
                <div className="w-[100%] flex">
                    <div className="w-[30%] px-6  py-3">
                        <a href="/">
                            <img src={Logo} alt="Logo" className="h-[60px]" />
                        </a>
                    </div>
                    <div className="w-[70%] flex bg-primary px-6  py-3">
                        <div className="w-[80%]">
                            <div className="sub-heading pt-5 text-secondary text-[24px]">
                                RVS <span className="sub-heading text-white  text-[24px]"> YUVA</span>
                            </div>
                        </div>
                        <div className="w-[20%]">
                            <div className="flex justify-end" onClick={() => eventHandler()}>
                                <div className="pt-6">
                                    {navbar ? <HiXMark className="text-[30px] text-white" /> : <HiViewList className="text-[30px] text-white" />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-primary mobile-view" >
                    <ul className="py-2">
                        {/* <li class="li mobile-view-border py-2 px-6"><NavLink to="/" className="home-li-a text-white py-1 hover:text-secondary">Home</NavLink></li> */}
                        <li class="li mobile-view-border py-2 px-6"><NavLink to="/about" className="home-li-a text-white py-1 hover:text-secondary">About us</NavLink></li>
                        <li class="li mobile-view-border py-2 px-6"><NavLink to="/scholarships" className="home-li-a text-white py-1 hover:text-secondary">Scholarship</NavLink></li>
                        {/* <li class="li mobile-view-border py-2 px-6"><NavLink to="/pedagogy" className="home-li-a text-white py-1 hover:text-secondary">Pedagogy</NavLink></li> */}
                        <li class="li mobile-view-border py-2 px-6"><NavLink to="/activity-club" className="home-li-a text-white py-1 hover:text-secondary">Activity Clubs</NavLink></li>
                        {/* <li class="li mobile-view-border py-2 px-6"><NavLink to="/yuvamelodies" className="home-li-a text-white py-1 hover:text-secondary">Melodies</NavLink></li> */}
                        <li class="li mobile-view-border py-2 px-6"><NavLink to="/contact-us" className="home-li-a text-white py-1 hover:text-secondary">Contact Us</NavLink></li>
                    </ul>

                </div>
            </div>

            {/* Desktop View */}


            <div className="bg-primary desktop-nav">
                {/* <div className="bg-black">
                <div className="container mx-auto">
                    <div className="py-1 flex justify-end">
                       <p className="text-white py-1 px-1"> Follow us</p>
                        <div className="mx-2 py-2">
                            <RiFacebookLine className="text-white text-h5" />
                        </div>
                        <div className="mx-2 py-2">
                            <RiInstagramLine className="text-white text-h5" />
                        </div>
                        <div className="mx-2 py-2">
                            <RiTwitterLine className="text-white text-h5" />
                        </div>
                        <div className="mx-2 py-2">
                            <RiWhatsappLine className="text-white text-h5" />
                        </div>
                    </div>
                </div>
            </div> */}
                <div className="mx-16">
                    <div className="bg-white ">
                        <div className="w-[100%] flex">
                            <div className="w-[100%] relative  bg-primary pb-7">
                                <div className="absolute centered">
                                    <a href="/">
                                        <img src={Logo} alt="Logo" className="h-[60px]" />
                                    </a>
                                </div>
                                <ul className="flex items-center place-content-between px-16">
                                    {/* <li class="li  pt-6"><NavLink to="/" className="home-li-a text-white py-6 hover:text-secondary">Home</NavLink></li> */}
                                    <li class="li  pt-6"><NavLink to="/about" className="home-li-a text-white py-6 hover:text-secondary">About us</NavLink></li>
                                    <li class="li  pt-6"><NavLink to="/scholarships" className="home-li-a text-white py-6 hover:text-secondary">Scholarship</NavLink></li>
                                    {/* <li class="li pt-6"><NavLink to="/employability" className="home-li-a text-white py-6 hover:text-secondary">Employability</NavLink></li> */}
                                    {/* <li class="li  pt-6"><NavLink to="/pedagogy" className="home-li-a text-white py-6 hover:text-secondary">Pedagogy</NavLink></li> */}
                                    <li class="li pt-6"><NavLink to="/activity-club" className="home-li-a text-white py-6  hover:text-secondary" >Activity Clubs</NavLink></li>
                                    {/* <li class="li pt-6"><NavLink to="/yuvamelodies" className="home-li-a text-white py-6 hover:text-secondary">Melodies</NavLink></li> */}
                                    <li class="li  pt-6"><NavLink to="/contact-us" className="home-li-a text-white py-6 hover:text-secondary">Contact Us</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar