import React from "react";
// import AttarctionImages from "../../Asset/Images/attraction.jpg"
import artist from "../../Asset/Images/About/icons/artist.png"
import music from "../../Asset/Images/About/icons/music.png"
import picture from "../../Asset/Images/About/icons/picture.png"
import rhythm from "../../Asset/Images/About/icons/rhythm.png"
import film from "../../Asset/Images/About/icons/short-film.png"
import sports from "../../Asset/Images/About/icons/sports.png"


function RVSYuvaIcons() {
    return (
        <div>
            <div className="bg-background">
                <div className="relative ">
                    {/* <img src={AttarctionImages} className="h-[100vh] w-[100%] object-cover" /> */}
                    <div className="top-0 h-[100%] w-[100%] ">
                        <div className="container mx-auto py-16">

                            <h2 className="text-primary sm:text-h2 sub-heading text-center text-h1 pb-3">RVS  <span className="text-secondary sm:text-h2 sub-heading text-h1"> YUVA</span> </h2>


                            <div className="w-[100%] flex flex-wrap pb-8 pt-4">
                                <div className="w-[32%] py-8 sm:w-[100%] mdsm:w-[48%] lg:w-[33%]">
                                    <div className="w-[80%] mx-auto">
                                        <div>
                                            <img src={music} alt="music" className="h-[100px] w-[100px]" />
                                        </div>
                                        <div className="text-h2 sub-heading text-secondary py-4 sm:text-h4 capitalize">MELODIES </div>
                                        <p className="text-text-color leading-[26px]  sm:text-sm">Vocal and instruments training in classical and semi classical music.</p>
                                    </div>
                                </div>
                                <div className="w-[32%] py-8 sm:w-[100%] mdsm:w-[48%] lg:w-[33%]">
                                    <div className="w-[80%] mx-auto">
                                        <div>
                                            <img src={picture} alt="picture" className="h-[100px] w-[100px]" />
                                        </div>
                                        <div className="text-h2 sub-heading text-secondary py-4 sm:text-h4">CAPTURE</div>
                                        <p className="text-text-color leading-[26px]  sm:text-sm">It is the ideal starting point for turning into a great director.</p>
                                    </div>
                                </div>
                                <div className="w-[32%] py-8 sm:w-[100%] mdsm:w-[48%] lg:w-[33%]">
                                    <div className="w-[80%] mx-auto">
                                        <div>
                                            <img src={rhythm} alt="rhythm" className="h-[100px] w-[100px]" />
                                        </div>
                                        <div className="text-h2 sub-heading text-secondary py-4 sm:text-h4">RHYTHM</div>
                                        <p className="text-text-color leading-[26px]  sm:text-sm">Choreographies done on popular genres</p>
                                    </div>
                                </div>
                                <div className="w-[32%] py-8 sm:w-[100%] mdsm:w-[48%] lg:w-[33%]">
                                    <div className="w-[80%] mx-auto">
                                        <div>
                                            <img src={artist} alt="artist" className="h-[100px] w-[100px]" />
                                        </div>
                                        <div className="text-h2 sub-heading text-secondary py-4 sm:text-h4">ARTISTRY</div>
                                        <p className="text-text-color leading-[26px]  sm:text-sm">It is the ideal starting point for turning into a great director.</p>
                                    </div>
                                </div>
                                <div className="w-[32%] py-8 sm:w-[100%] mdsm:w-[48%] lg:w-[33%]">
                                    <div className="w-[80%] mx-auto">
                                        <div>
                                            <img src={sports} alt="sports" className="h-[100px] w-[100px]" />
                                        </div>
                                        <div className="text-h2 sub-heading text-secondary py-4 sm:text-h4">SPORTS</div>
                                        <p className="text-text-color leading-[26px]  sm:text-sm">Training for different levels of matches will be provided.</p>
                                    </div>
                                </div>
                                <div className="w-[32%] py-8 sm:w-[100%] mdsm:w-[48%] lg:w-[33%]">
                                    <div className="w-[80%] mx-auto">
                                        <div>
                                            <img src={film} alt="film" className="h-[100px] w-[100px]" />
                                        </div>
                                        <div className="text-h2 sub-heading text-secondary py-4 sm:text-h4"> SHORT FILM</div>
                                        <p className="text-text-color leading-[26px]  sm:text-sm">It is the ideal starting point for turning into a great director.</p>
                                    </div>
                                </div>
                            </div>

                            <div>

                            </div>
                        </div>

                    </div>
                </div>




            </div>

        </div>
    )
}
export default RVSYuvaIcons