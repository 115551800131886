import React from 'react'
import Page404 from '../../Asset/Images/pagenotfound.jpg'

export default function PageNotFound() {
  return (
    <div className='flex justify-center mt-5 sm:mt-[60px] text-center sm:flex sm:justify-center'>
        <img src={Page404} alt="404 Page404" width='800px' className=''/>
    </div>
  )
}
