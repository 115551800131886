import React from 'react'
import { RiFacebookLine, RiInstagramLine } from "react-icons/ri";

export default function Capture() {
  return (
    <div className='pt-16'>
        <div className='container mx-auto pt-10 capture-background-image px-4'>
        <div className='center'>
        <h2 className="text-primary sm:text-h2 sm:text-center sub-heading text-h1 pb-3">YUVA <span className="text-secondary sm:text-h2 sm:text-center sub-heading text-h1 pb-3">CAPTURE</span> </h2>
        </div>
        <p className='text-text-color leading-[26px] tracking-wide pb-3 sm:text-sm'>
        Our eyes catch pictures before the camera sees them. Everyone can buy a camera, but not everyone can capture stunning photos. That's why RVS YUVA CAPTURE was formed. Students passionate about photography can join this club to develop and learn fresh photographic concepts.
        </p>
        <p className='text-text-color leading-[26px] tracking-wide pb-3 sm:text-sm'>
        Photos are more than simply images; they save memories for a lifetime and convey a narrative. Every picture tells a story; it expresses emotions and may voice out things that cannot be voiced. Some moments pass just like that, and it's essential to record them before they vanish. There are different ways of conveying things; some can be done through music, some through dance, and unspoken words are communicated through pictures.
        </p>
        <p className='text-text-color leading-[26px] tracking-wide pb-3 sm:text-sm'>
        RVS YUVA CAPTURE will provide the students instructions with unique ideas and "Your Best image comes from your first 10,000 shots." The best is yet to come, so don't worry. We never stop striving for the student's success. RVS YUVA CAPTURE provides an opportunity to tell inspiring stories, capturing those ideas that are not spoken about much or the ideas viewed from different angles that have yet to be discussed. The activities to be conducted will be informed soon.
        </p>
        <div className='w-[100%]'>
          <div className='flex justify-end'>
            <a href="https://www.facebook.com/RVSYuvaSulur.capture?mibextid=ZbWKwL">
            <div className='group cursor-pointer transition07 mx-3'>
              <div className='bg-[#d2d2d3] p-[2px] group-hover:bg-secondary'>
                <RiFacebookLine className='text-secondary text-h2 transition07 group-hover:text-white ' />
              </div>
            </div>
            </a>
            <a href="https://www.instagram.com/rvsyuvasulur.capture/">
            <div className='group cursor-pointer transition07'>
              <div className='bg-[#d2d2d3] p-[2px] group-hover:bg-secondary'>
                <RiInstagramLine className='text-secondary text-h2 transition07 group-hover:text-white ' />
              </div>
            </div>
            </a>
          </div>
        </div>
        </div>
    </div>
  )
}
