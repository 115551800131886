import React, {  useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import PartnersImage from "../../Assets/Images/About-Program/Partners/partners.png"

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import "./styles.css";
// import required modules
import { Pagination, Keyboard } from "swiper";
import TrusteeSir from "../../Asset/Images/About/Trustee.webp"
// import samuelMam from "../../Asset/Images/About/samuel.png"


function Scope() {
  const [values, setValues] = useState()
  const tabs = [
    {
      id: 1,
      name: "VISION",
      para: "Advancing and nurturing talent without boundaries. Empowering is repeated so many times",
      status: true,
    },
    {
      id: 2,
      name: "MISSION",
      para: "Uplifting and empowering students from underrepresented backgrounds who are looking for a way to recognise their dreams by providing them with equal opportunities and the skills they need to succeed.",
      status: false,
    },
  ]


  // const handleChnage = (v) => {
  //   let filterdata = tabs.forEach((vi) => {
  //     if (v.id === vi.id) {
  //       vi.status = true
  //     } else {
  //       vi.status = false
  //     }
  //     setValues(tabs)
  //   })
  //   console.log(tabs)

  // }
  useEffect(() => {
    setValues(tabs)
  }, [])

  return (
    <div>


      <div className="container mx-auto py-16">
        <div>
          <h2 className="text-primary sub-heading text-center sm:text-h2 text-h1 pb-3">T<span className="text-secondary sub-heading text-h1 sm:text-h2">EAM</span> </h2>
          <div className="w-[100%] sm:block mdsm:block flex pb-8 lg:flex">
            <div className="w-[70%] sm:w-[100%] mdsm:w-[100%] lg:w-[70%]">

              <div className="overflow-hidden">
                <div className="w-[100%] mx-auto relative">
                  <div className=" ">

                    <Swiper
                      slidesPerView={4}
                      spaceBetween={30}
                      keyboard={{
                        enabled: true,
                      }}
                      pagination={{
                        clickable: true,
                      }}

                      breakpoints={{
                        300: {
                          slidesPerView: 1,
                          spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 1,
                          spaceBetween: 40,
                        },
                        1024: {
                          slidesPerView: 1,
                          spaceBetween: 50,
                        },
                      }}
                      modules={[Keyboard, Pagination]}
                      className="mySwiper"
                    >
                      <SwiperSlide>

                        <div className="p-8 group sm:p-4 ">
                          <img src={TrusteeSir} alt="Trustee Sir" className="w-[200px] mx-auto my-4 h-[200px] grop-hover:border-secondary object-cover borderRadius50 border-4 border-primary" />
                          <p className="text-text-color leading-[26px] px-10 py-4 pb-2 text-center sm:text-sm sm:px-2">RVS Group of Instutions, which has nearly 50,000 students enrolled
                            in over 50 institutions, is led by Dr. K Senthil Ganesh as Managing Trustee.
                            He began contributing to the expansion of the RVS group since 2000.
                            He has nearly 25 years of experience in top management roles,
                            as well as marketing, finance, and strategy, among many other
                            areas of business. For more than three and a half decades,
                            RVS has been empowering students through education.
                            As an exceptional academic scholar with a focus on empowerment,
                            he is now taking the humble step of serving the community and
                            people from the marginalised category by providing numerous
                            trainings from experts in the field in order to
                            empower them and open the door for them to achieve their goals.</p>

                          <p className="text-text-color leading-[26px] px-10 py-4 text-center sm:px-2 sm:text-sm">Under his able leadership, we have experts in the relevant fields  with whose support,
                            youth will be nurtured towards better employability.</p>

                          <p className="text-secondary  sub-heading sm:text-h5 text-center text-h2 pb-3">Dr.K. Senthil Ganesh    </p>
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[30%] sm:w-[100%] sm:px-4 mdsm:w-[100%] mdsm:px-10 lg:px-8 lg:w-[30%]">
              <div className="pt-10 sm:pt-5">
                <div className="group sm:px-2">
                  <p className={`sub-heading pt-4 sm:text-h5 group-hover:text-primary text-h2 transition07 text-secondary underline-offset-4 underline text-primarytext-secondary`}>VISION</p>
                  <p className="text-text-color sm:text-sm leading-[26px] pt-4">Advancing and nurturing talent without boundaries. 

                  </p>
                </div>


                <div className="group sm:px-2">


                  <p className={`sub-heading pt-4 sm:text-h5 group-hover:text-primary text-h2 transition07 text-secondary underline-offset-4 underline text-primarytext-secondary`}>MISSION</p>
                  <p className="text-text-color sm:text-sm leading-[26px] pt-4">Uplifting and empowering students from under
privileged sections, providing a platform  to recognise their dreams and giving them  equal opportunities and the skills they need to succeed.

                  </p>
                </div>

              </div>



            </div>




          </div>
        </div>
      </div>
    </div>
  )
}
export default Scope