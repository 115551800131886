import React from "react";
import CommingSoon from "../Hub-Components/CommingSoon";
// import FooterSection from "../Hub-Components/Footer";
// import Navbar from "../Hub-Components/Navbar";

function EmployabilityPage(){
    return(
        <div>
            {/* <Navbar/> */}
            <CommingSoon/>
            {/* <FooterSection/> */}
        </div>
    )
}
export default EmployabilityPage