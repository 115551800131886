import React from 'react'
import { RiFacebookLine, RiInstagramLine } from "react-icons/ri";
export default function Melodies() {
  return (
    <div className='pt-16 '>
      <div className='container mx-auto pt-10 melodies-background-image px-4'>
        <div className='center'>
          <h2 className="text-primary sm:text-h2 sm:text-center sub-heading text-h1 pb-3">YUVA <span className="text-secondary sm:text-h2 sm:text-center sub-heading text-h1 pb-3">MELODIES</span> </h2>
        </div>
        <p className='text-text-color leading-[26px] tracking-wide pb-3 sm:text-sm'>
          Music is a more effective means of expressing our feelings than words are when we cannot. One of the notions says that making music involves fusing shape, harmony, melody, rhythm, and other expressive components with sound
        </p>
        <p className='text-text-color leading-[26px] tracking-wide pb-3 sm:text-sm'>
          Music plays a significant role in social gatherings, religious events, rites of passage, festivals, and cultural activities. The music industry includes songwriters, performers, sound engineers, producers, concert managers, and distributors of musical instruments. All students who appreciate music are welcome to join this club. Students receive non-academic education, providing a fantastic foundation on which to launch their careers.
        </p>
        <p className='text-text-color leading-[26px] tracking-wide pb-3 sm:text-sm'>
          RVS YUVA SULUR reached out to Mr. Srinivas; he accepted this humble request, visited our campus, met the students, and shared his experience. Further, he met the first batch of students, reviewed their singing and instrumental performances, and gave valuable suggestions. He suggested two dynamic and expert singers for training students, Sreeraj Sahajan and Lakshmi Ennappadam.
        </p>
        <p className='text-text-color leading-[26px] tracking-wide pb-3 sm:text-sm'>
          RVS YUVA MELODIES is where students can share their songs and performances. It enables the students to demonstrate their vocal and musical abilities. It is also the first step in achieving acknowledgment of their abilities. Every time students step forward to record their performance, they feel encouraged, and their hesitations are broken, allowing them to develop their abilities further.
        </p>
        <p className='text-text-color leading-[26px] tracking-wide pb-3 sm:text-sm'>
          The students can perform in any language. Those with a musical ear, a fine singing voice, a good grip, and a love of melodies and rhythm are encouraged to join the club. Students are also given training materials and other vocal hacks to practice, as well as instrumental skills and jamming sessions. They are encouraged to express themselves through the song in their unique way.
        </p>
        <div className='w-[100%]'>
          <div className='flex justify-end'>
            <a href="https://www.facebook.com/RVSYuvaSulur.Melodies?mibextid=ZbWKwL">
            <div className='group cursor-pointer transition07 mx-3'>
              <div className='bg-[#d2d2d3] p-[2px] group-hover:bg-secondary'>
                <RiFacebookLine className='text-secondary text-h2 transition07 group-hover:text-white ' />
              </div>
            </div>
            </a>
            <a href="https://www.instagram.com/rvsyuvasulur.melodies/">
            <div className='group cursor-pointer transition07'>
              <div className='bg-[#d2d2d3] p-[2px] group-hover:bg-secondary'>
                <RiInstagramLine className='text-secondary text-h2 transition07 group-hover:text-white ' />
              </div>
            </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
